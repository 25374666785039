/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { observer } from 'remx';

import dayjs from 'dayjs';
import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { get } from '../../../libs/client';

import { QuickLine } from '../../../components/charts';
import MiniEventsTable from '../../../components/tables/MiniEvents';
import stores from '../../../libs/stores';
import { Thousandify } from '../../../utils/StringUtils';

// import { DashboardGrapher } from '../../../utils/StringUtils';
// import strings from '../../../localization';

const NearEvents = ({
  events, monthly, selected, onSelect,
}) => (
  <section className="grid grid-4 grid-1-m">
    <main className="main d-flex flex-column fw-100-m">
      <section>
        <h5 className="mb-2">Yaklaşan 5 Etkinlik</h5>
        <hr className="m-0" />
      </section>
      <section style={{ overflowY: 'scroll', paddingTop: '0.75rem' }}>
        <MiniEventsTable selected={selected} events={events} onSelect={onSelect} />
      </section>
    </main>
    <main className="main d-flex flex-column dashboard-table">
      <section>
        <h5 className="mb-2">{selected ? `${selected.name} Satışları` : 'Yaklaşan Tüm Etkinliklerin Satışları'}</h5>
        <hr className="m-0" />
      </section>
      <section className="position-relative min-height" style={{ flex: 1 }}>
        {monthly?.length > 0 ? (
          <QuickLine
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: { display: false },
                title: { display: false },
              },
            }}
            data={{
              labels: Array.from(Array(31)).map(((_, i) => dayjs.utc().subtract(i, 'days').format('D MMM'))).reverse(),
              datasets: [
                {
                  label: 'Bilet',
                  lineTension: 0,
                  data: Array.from(Array(31)).map((_, i) => monthly.find((m) => m._id === dayjs.utc().subtract(i, 'days').format('YYYY-MM-DD'))?.count || 0).reverse(),
                  borderColor: '#017bfe',
                },
              ],
            }}
          />
        )
          : (
            <CenteredInformation
              title=" Veri Yok"
              subtitle="Satışlarınız başladığı zaman burada gözükür."
            />
          )}
      </section>
    </main>
  </section>
);

const StatCard = ({ title, stats }) => (
  <main className="main">
    <p>{title || 'N/A'}</p>
    {stats?.sales > 0 ? (
      <h4 className="mb-0">
        <span className="h2 mr-2">{stats?.sales || 0}</span>
        bilet satıldı
      </h4>
    ) : (
      <h4 className="mb-0">
        <span className="h2 mr-2">Hiç</span>
        bilet satılmamış
      </h4>
    )}
    <b className={stats?.revenue > 0 ? 'text-success' : 'text-secondary'}>
      {stats?.revenue > 0 ? `+${Thousandify((stats?.revenue || 0).toFixed(2))} TRY` : 'Bir değişiklik yok'}
    </b>
  </main>
);

const Home = () => {
  const [events, setEvents] = useState(null);
  const [monthly, setMonthly] = useState(null);

  const [stats, setStats] = useState({
    daily: Math.floor(Math.random() * 100),
    weekly: Math.floor(Math.random() * 100),
    monthly: Math.floor(Math.random() * 100),
    quarterly: Math.floor(Math.random() * 100),
  });

  const [selected, setSelected] = useState('');

  const GetEvents = () => get(`/events/search?recent=${true}&page=0&limit=${5}`)
    .then(({ data }) => {
      setEvents(data);
    }).catch(console.log);

  const GetMonthly = () => get(`/reports/organizer/monthly/events/${selected?._id || ''}`)
    .then(({ data }) => { setMonthly(data); }).catch(console.log);

  const GetStats = () => Promise.all([
    get(`/reports/organizer/stats/events/${selected?._id || ''}?end=${dayjs.utc().format('YYYY-MM-DD')}&start=${dayjs.utc().format('YYYY-MM-DD')}`),
    get(`/reports/organizer/stats/events/${selected?._id || ''}?end=${dayjs.utc().format('YYYY-MM-DD')}&start=${dayjs.utc().startOf('week').format('YYYY-MM-DD')}`),
    get(`/reports/organizer/stats/events/${selected?._id || ''}?end=${dayjs.utc().format('YYYY-MM-DD')}&start=${dayjs.utc().startOf('month').format('YYYY-MM-DD')}`),
    get(`/reports/organizer/stats/events/${selected?._id || ''}?end=${dayjs.utc().format('YYYY-MM-DD')}&start=${dayjs.utc().startOf('month').subtract(3, 'months').format('YYYY-MM-DD')}`),
  ]).then((data) => setStats({
    daily: data[0].data,
    weekly: data[1].data,
    monthly: data[2].data,
    quarterly: data[3].data,
  })).catch(console.log);

  useEffect(() => { GetEvents(); }, []);
  useEffect(() => { GetMonthly(); GetStats(); }, [selected]);

  useEffect(() => {
    stores.setAppbar({
      left: (
        <>
          <h2 className="m-0">ES Events Admin Paneli</h2>
        </>
      ),
      right: (null),
    });
  }, []);

  return (
    events ? (
      <main className="grid">
        <section className="grid grid-4">
          <StatCard
            title="Bugün"
            stats={stats.daily}
          />
          <StatCard
            title="Bu Hafta"
            stats={stats.weekly}
          />
          <StatCard
            title="Bu Ay"
            stats={stats.monthly}
          />
          <StatCard
            title="Bu Çeyrek"
            stats={stats.quarterly}
          />
        </section>
        <NearEvents events={events} selected={selected} onSelect={setSelected} monthly={monthly} />
      </main>
    )
      : <LoadingIndicator />
  );
};

export default observer(Home);
