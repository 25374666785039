/* eslint-disable jsx-a11y/label-has-associated-control */
import strings from '../../localization';

import CommonInput from '../widgets/CommonInput';
import CommonSelect from '../widgets/CommonSelect';

const EventTicketsTable = ({
  register, currency, disabled,
  fieldArray,
}) => (
  <div>
    <label>Biletler</label>
    <table className="w-100">
      <thead>
        <tr>
          <th>Kategori</th>
          <th>Adı</th>
          <th className="text-right">Fiyatı</th>
          <th className="text-right">Stok</th>
          <th className="text-right">Satılan</th>
          <th className="text-right">Satılacak</th>
          <th className="text-left">Durumu</th>
          {!disabled && (<th className="text-center">Sil</th>)}
        </tr>
      </thead>
      <tbody>
        {fieldArray.fields.map((ticket, i) => (
          <tr key={ticket.id}>
            <td>
              <CommonInput
                disabled={disabled}
                size="sm"
                placeholder="Kategori adı"
                register={register(`tickets.${i}.category`, { required: true, minLength: 1 })}
              />
            </td>
            <td>
              <CommonInput
                disabled={disabled}
                size="sm"
                placeholder="Satış adı"
                register={register(`tickets.${i}.name`, { required: true, minLength: 1 })}
              />
            </td>
            <td align="right">
              <CommonInput
                disabled={disabled}
                size="sm"
                type="number"
                style={{ width: '6rem' }}
                defaultValue=""
                placeholder="Fiyatı"
                register={register(`tickets.${i}.price`, { required: true, min: 1 })}
                textAlign="right"
                prepend={currency}
              />
            </td>
            <td align="right">
              <CommonInput
                disabled={disabled}
                size="sm"
                type="number"
                placeholder="Stok"
                style={{ width: '6rem' }}
                register={register(`tickets.${i}.stock`, { required: true, min: 0, valueAsNumber: true })}
                textAlign="right"
              />
            </td>
            <td align="right">
              <CommonInput
                style={{ width: '5rem' }}
                disabled
                size="sm"
                type="number"
                value={(ticket?.sold || 0)}
                placeholder="Yok"
                textAlign="right"
              />
            </td>
            <td align="right">
              <CommonInput
                style={{ width: '5rem' }}
                disabled
                size="sm"
                type="number"
                value={
                    (ticket?.stock - (ticket?.sold || 0)) > 0
                      ? (ticket?.stock - (ticket?.sold || 0))
                      : 0
                  }
                placeholder="Yok"
                textAlign="right"
              />
            </td>
            <td align="right">
              <CommonSelect
                disabled={disabled}
                size="sm"
                style={{ width: '5rem' }}
                  // disabled={status !== 'active'}
                placeholder="Lütfen bir durum seçin"
                defaultValue={ticket.active}
                register={register(`tickets.${i}.active`)}
                options={[
                  { value: true, name: strings.active },
                  { value: false, name: strings.inactive },
                ]}
              />
            </td>
            {!disabled && (
            <td align="center">
              <button
                disabled={disabled}
                type="button"
                className="table-button btn btn-danger"
                onClick={() => fieldArray.remove(i)}
              >
                <i className="fa fa-remove" />
              </button>
            </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
export default EventTicketsTable;
