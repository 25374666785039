/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
// import { useForm } from 'react-hook-form';

import { CenteredInformation, LoadingIndicator, SeatingEngine } from '../../../../../components/utils';

import { get } from '../../../../../libs/client';

const Seating = ({ data }) => (
  <form>
    <details open>
      <summary className="d-flex align-items-center">
        <h4 className="flex-grow-1 m-0 mb-2">
          {data.name}
        </h4>
      </summary>
      <SeatingEngine readonly plan={data} className="mt-2" />
    </details>
  </form>
);

const VenueSeating = ({ params }) => {
  const [seatings, setSeatings] = useState(null);

  const GetSeatings = () => get(`/venues/${params.id}/seating`)
    .then(({ data }) => setSeatings(data))
    .catch((err) => console.log(err));

  useEffect(() => { GetSeatings(); }, []);

  return (
    seatings
      ? (
        seatings.length > 0
          ? (
            <main className="grid">
              {(seatings).map((seating) => (<Seating key={seating._id} data={seating} />))}
            </main>
          ) : (
            <CenteredInformation
              title="Oturma planı yok"
              subtitle="Mekana ait bir oturma planı eklenmemiş"
              fixed
            />
          )
      )
      : <LoadingIndicator />
  );
};

export default VenueSeating;
