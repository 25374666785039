/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ConfirmationChip, LoadingIndicator } from '../../../../components/utils';
import { AsyncEntitySelect, CommonInput, CommonSelect } from '../../../../components/widgets';

import {
  // post,
  get, put,
} from '../../../../libs/client';
import stores from '../../../../libs/stores';

import strings from '../../../../localization';
import OrganizerSale from '../../../../components/utils/OrganizerSale';

const CURRENCIES = [
  { value: 'TRY', name: 'TRY' },
  { value: 'USD', name: 'USD' },
  { value: 'EUR', name: 'EUR' },
];

const EventBasicInfo = ({
  show, step, page, params, onSaleChange, onVenueChange,
}) => {
  const {
    register, control, watch,
    // handleSubmit,
  } = useForm();

  const [venue] = [watch('venue')];
  const [sale, setSale] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [providers, setProviders] = useState([]);

  const GetSale = useCallback(() => get(`/events/${params.eid}/sales/${params.id}`)
    .then(({ data }) => setSale(data))
    .catch((err) => console.log(err)), []);

  const GetProviders = useCallback(() => get('/organizers/providers')
    .then(({ data }) => setProviders(data.map(({ _id, name, bank }) => ({ value: _id, name: `${name} (${bank})` }))))
    .catch((err) => console.log(err)), []);

  const GetContracts = useCallback(() => get('/organizers/contracts')
    .then(({ data }) => setContracts(data.map(({ _id, name }) => ({ value: _id, name }))))
    .catch((err) => console.log(err)), []);

  // const CreateSale = (form) => post(`/events/${params.eid}/sales`, form)
  //   .then(() => alert('Etkinlik satışı oluşturuldu'))
  //   .catch(() => alert('Etkinlik satışı oluştururken bir hata oldu'));

  const UpdateSale = (form) => put(`/events/${params.eid}/sales/${params.id}`, form)
    .then(() => { alert('Satış bilgileri güncellendi'); GetSale(); })
    .catch(() => alert('Satış bilgileri güncellenirken bir hata oldu'));

  useEffect(() => { GetSale(); GetContracts(); GetProviders(); }, []);

  useEffect(() => {
    stores.setAppbar({
      back: '?step=2',
      left: (
        <h2 className="m-0">{sale ? `${sale?.event?.name} satış detayları` : 'Yeni Satış'}</h2>
      ),
      right: (
        <div>
          {params.id !== 'new' && (
          <button
            type="button"
            className={`btn btn-${sale?.active ? 'danger' : 'success'} mr-2`}
            onClick={() => UpdateSale({ active: !sale.active })}
          >
            <i className={`fa fa-${sale?.active ? 'ban' : 'check'} mr-2`} />
            {sale?.active ? 'SATIŞI GİZLE' : 'SATIŞI LİSTELE'}
          </button>
          )}
          {/* <button
            type="button"
            className="btn btn-primary"
            disabled={step !== 0}
            onClick={handleSubmit(params.id === 'new' ? CreateSale : UpdateSale)}
          >
            <i className="fa fa-save mr-2" />
            {params.id === 'new' ? 'SATIŞ OLUŞTUR' : 'DEĞİŞİKLİKLERİ KAYDET'}
          </button> */}
        </div>
      ),
    });
    if (sale) {
      stores.setBreadCrumbs([
        { to: '/events', title: 'Etkinlikler' },
        { to: `/events/${sale?.event?._id}`, title: sale?.event?.name },
        { to: `/events/${sale?.event?._id}?step=1`, title: 'Etkinlik Satışları' },
        { to: `/events/${sale?.event?._id}/sales/${sale?._id}`, title: sale?.venue?.name },
        { to: `/events/${sale?.event?._id}/sales/${sale?._id}`, title: page },
      ]);
    }
  }, [step, sale]);

  useEffect(() => venue && onVenueChange(venue), [venue]);
  useEffect(() => sale && onSaleChange(sale), [sale]);

  return (
    (sale || params.id === 'new') ? (
      <section className="grid" style={{ display: show ? 'grid' : 'none' }}>
        <form>
          <h4>Temel Bilgiler</h4>
          <section className="grid grid-2">
            <Controller
              control={control}
              name="venue"
              render={({ field: { onChange } }) => (
                <AsyncEntitySelect
                  label="Mekan"
                  defaultValue={sale?.venue}
                  placeholder="Bir mekan arayın"
                  disabled={params.id !== 'new'}
                  register={register('venue')}
                  entity="venues"
                  onChange={onChange}
                />
              )}
            />
            <section className="grid grid-3">
              <OrganizerSale sale={sale} onClose={() => null} />
              <div className="mb-0 d-flex flex-column justify-content-end">
                <label title="OKAY">ES Events Satış İzni</label>
                <ConfirmationChip
                  label={sale?.vendor_confirmation ? strings[`sale-confirm-${sale?.vendor_confirmation}`] : 'Yeni Satış'}
                  status={sale?.vendor_confirmation || 'pending'}
                  disabled={!sale?.venue_confirmation}
                  onClick={() => {
                    if (sale?.venue_confirmation === 'confirmed') {
                      UpdateSale({ vendor_confirmation: (sale?.vendor_confirmation === 'confirmed' ? 'pending' : 'confirmed') });
                    } else {
                      alert('Mekan onayı olmadan satışı onaylayamazsınız.');
                    }
                  }}
                />
              </div>
              <div className="mb-0 d-flex flex-column justify-content-end">
                <label title="OKAY">Mekan Satış İzni</label>
                <ConfirmationChip
                  label={sale?.venue_confirmation ? strings[`sale-confirm-${sale?.venue_confirmation}`] : 'Yeni Satış'}
                  status={sale?.venue_confirmation || 'pending'}
                />
              </div>
            </section>
          </section>
          <section className="grid grid-2">
            <section className="grid grid-2">
              <CommonSelect
                label="Ödeme Sağlayıcı"
                placeholder="Lütfen bir sağlayıcı seçin"
                defaultValue={sale?.provider}
                // TODO Change for organizer
                disabled={params.id !== 'new'}
                options={providers}
                register={register('provider')}
              />
              <CommonSelect
                label="Para Birimi"
                placeholder="Lütfen bir para birimi seçin"
                defaultValue={sale?.currency}
                options={CURRENCIES}
                disabled={params.id !== 'new'}
                register={register('currency')}
              />
            </section>
            <CommonSelect
              label="Satış Sözleşmesi"
              placeholder="Lütfen bir sözleşme seçin"
              defaultValue={sale?.contract}
              options={contracts}
              disabled={params.id !== 'new'}
              register={register('contract')}
            />
          </section>
        </form>
        <form>
          <h4>Ek Bilgiler ve Kurallar</h4>
          <section className="grid grid-2">
            <CommonInput
              label="Türkçe Kurallar"
              placeholder="Etkinliğinizin bu mekandaki satışı için ek kurallar veya bilgilendirme ekleyebilirsiniz."
              register={register('rules.tr', { maxLength: 1000 })}
              length={sale?.rules?.tr?.length}
              defaultValue={sale?.rules?.tr}
              limit={1000}
              multiline
              multilineHeight={15}
              disabled
            />
            <CommonInput
              label="İngilizce Kurallar"
              placeholder="Etkinliğinizin bu mekandaki satışı için ek kurallar veya bilgilendirme ekleyebilirsiniz."
              register={register('rules.en', { maxLength: 1000 })}
              length={sale?.rules?.en?.length}
              defaultValue={sale?.rules?.en}
              limit={1000}
              multiline
              multilineHeight={15}
              disabled
            />
          </section>
        </form>
      </section>
    ) : <LoadingIndicator />
  );
};

export default EventBasicInfo;
