/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { get } from '../../libs/client';
import { CommonInput, CommonSelect } from '../widgets';

const Address = ({ data, watch, register }) => {
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [streets, setStreets] = useState([]);

  const location = watch([
    'address.city',
    'address.district',
    'address.neighborhood',
    'address.street',
    'address.no',
    'address.door',
  ]);

  const GetCities = () => get('/iims/cities')
    .then((res) => setCities(res.data.map((v) => ({ value: v, name: v }))));
  const GetDistricts = () => get(`/iims/cities/${location[0]}/districts`)
    .then((res) => setDistricts(res.data.map((v) => ({ value: v, name: v }))))
    .catch((err) => console.log(err));
  const GetNeighboorhoods = () => get(`/iims/cities/${location[0]}/districts/${location[1]}/neighborhoods`)
    .then((res) => setNeighborhoods(res.data.map((v) => ({ value: v, name: v }))))
    .catch((err) => console.log(err));
  const GetStreets = () => get(`/iims/cities/${location[0]}/districts/${location[1]}/neighborhoods/${location[2]}/streets`)
    .then((res) => setStreets(res.data.map((v) => ({ value: v, name: v }))))
    .catch((err) => console.log(err));

  useEffect(() => GetCities(), []);
  useEffect(() => location[0] && GetDistricts(), [location[0]]);
  useEffect(() => location[1] && GetNeighboorhoods(), [location[1]]);
  useEffect(() => location[2] && GetStreets(), [location[2]]);

  return (
    <section className="grid grid-2">
      <section className="grid grid-3">
        {/* <CommonInput
          defaultValue={data?.address?.city}
          register={register('address.city')}
        /> */}
        <CommonSelect
          label="Şehir"
          defaultOption={{ value: data?.address?.city, name: data?.address?.city }}
          defaultValue={data?.address?.city}
          placeholder="Bir şehir seçin"
          options={cities}
          register={register('address.city')}
        />
        <CommonSelect
          disabled={!location[0]}
          label="İlçe"
          defaultOption={{ value: data?.address?.district, name: data?.address?.district }}
          defaultValue={data?.address?.district}
          placeholder="Bir ilçe seçin"
          options={districts}
          register={register('address.district')}
        />
        <CommonSelect
          disabled={!location[1]}
          label="Mahalle"
          defaultOption={{ value: data?.address?.neighborhood, name: data?.address?.neighborhood }}
          defaultValue={data?.address?.neighborhood}
          placeholder="Bir mahalle seçin"
          options={neighborhoods}
          register={register('address.neighborhood')}
        />
        <CommonSelect
          disabled={!location[2]}
          label="Sokak / Cadde"
          defaultOption={{ value: data?.address?.street, name: data?.address?.street }}
          defaultValue={data?.address?.street}
          placeholder="Bir cadde seçin"
          options={streets}
          register={register('address.street')}
        />
        <CommonInput
          disabled={!location[3]}
          label="Numara"
          register={register('address.no', { required: true })}
          placeholder="örn: 17"
          defaultValue={data?.address?.no}
        />
        <CommonInput
          disabled={!location[4]}
          label="İç Kapı No"
          register={register('address.door')}
          placeholder="örn: 17"
          defaultValue={data?.address?.door}
        />
        {/* <CommonInput
          disabled={!location[4]}
          label="Posta Kodu"
          register={register('address.postal', { required: true })}
          placeholder="örn: 34034"
          defaultValue={data?.address?.postal}
        /> */}
      </section>
      <div className="map">
        <iframe
          src={`https://maps.google.com/maps?q=${(data?.address || location[0]) ? Object.values(location[0] ? location : Object.values(data?.address)).join(' ') : 'Istanbul'}&z=14&ie=UTF8&iwloc=&output=embed`}
          allowFullScreen={false}
          className="center"
          title="Google Maps"
          frameBorder="0"
        />
      </div>
    </section>
  );
};

export default Address;
