import { CommonInput } from '../../../../components/widgets';
// import strings from '../../../../localization';

const CraftgateForm = ({ register, provider }) => (
  <section className="grid grid-2">
    <CommonInput
      label="Craftgate API Key"
      register={register('credentials.craftgateApiKey')}
      defaultValue={provider?.credentials?.craftgateApiKey}
    />
    <CommonInput
      label="Craftgate Secret Key"
      register={register('credentials.craftgateSecret')}
      defaultValue={provider?.credentials?.craftgateSecret}
    />
  </section>
);

export default CraftgateForm;
