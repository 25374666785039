/* eslint-disable no-underscore-dangle */
import { Link } from 'wouter';
import dayjs from 'dayjs';

import { Thousandify } from '../../utils/StringUtils';

const colors = {
  success: 'success',
  pending: 'warning',
  refunded: 'danger',
};

// const saleRedir = {
//   iyzico: 'https://sandbox-merchant.iyzipay.com/transactions',
//   craftgate: 'https://sandbox-merchant.iyzipay.io/transactions',
// };

const currencies = {
  TRY: '₺',
  USD: '$',
  EUR: '€',
};

const SalesTable = ({ title, sales, pager }) => (
  <main className="main table-responsive">
    <h4 className="flex-grow-1 mb-3">{title}</h4>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Etkinlik Adı</th>
          <th scope="col">Mekan Adı</th>
          <th scope="col">Seans</th>
          <th scope="col">Kullanıcı</th>
          <th scope="col">Ref. No</th>
          {/* <th scope="col">Link</th> */}
          <th scope="col">Toplam</th>
          <th scope="col">Tarihi</th>
          <th scope="col" className="text-center">Durumu</th>
        </tr>
      </thead>
      <tbody>
        {sales.map((sale) => (
          <tr key={sale._id}>
            <td>
              <div>
                <img
                  className="mr-4"
                  style={{ objectFit: 'cover' }}
                  alt="event logo"
                  src={sale?.event?.image}
                  width={64}
                  height={64}
                />
                <Link className="text-2-line" to={`/events/${sale?.event?._id}`}>{sale?.event?.name}</Link>
              </div>
            </td>
            <td>
              <div>
                <Link to={`/events/${sale?.event?._id}/sales/${sale?.sale}`}>{sale?.venue?.name}</Link>
              </div>
            </td>
            <td>
              <div>
                <Link to={`/events/${sale?.event?._id}/sales/${sale?.sale}?session=${sale?.session?._id}&step=1`}>{dayjs.utc(sale?.session?.start).format('DD.MM.YYYY\nHH:mm')}</Link>
              </div>
            </td>
            <td><div><Link to={`/users/${sale.user}`}>{sale?.owner.name}</Link></div></td>
            <td>
              <div>
                {sale?.reference ? <Link to={`/sales/${sale?._id}`}>{sale?.reference}</Link> : <p>N/A</p>}
              </div>
            </td>
            {/* <td>
              <div>
                {sale?.reference
                  ? (
                    <a
                      href={`${saleRedir[sale?.provider?.provider]}/${sale.reference}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {sale.provider.provider}
                      <i className="fa fa-link ml-2" />
                    </a>
                  )
                  : 'N/A'}
              </div>
            </td> */}
            <td>
              <div>
                {sale?.paid ? `${Thousandify((sale?.paid).toFixed(2))} ${currencies[sale?.event?.currency] || currencies.TRY}` : 'N/A'}
              </div>
            </td>
            <td>
              <div>
                {`${dayjs.utc(sale.createdAt).format('DD.MM.YYYY\nHH:mm')}`}
              </div>
            </td>
            <td>
              <div>
                <button
                  type="button"
                  disabled
                  className={`m-0 w-100 btn btn-${colors[sale?.status]}`}
                >
                  {sale?.status}
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="float-right">{pager}</div>
  </main>
);

export default SalesTable;
