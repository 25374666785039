/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { observer } from 'remx';

import stores from '../../../libs/stores';
// import strings from '../../../localization';

import Pending from './Pending';
import NotAvailable from './NotAvailable';
import Done from './Done';

import { PaginationInput } from '../../../components/widgets';

const PAGES = ['Bekleyen Ödemelerim', 'Ödemesi Girilmemiş Satışlar', 'Ödenen Satışlar'];

const Sales = () => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    stores.setAppbar({
      left: (
        <h2 className="m-0">Etkinlik Ödemeleri</h2>
      ),
    });
  }, []);

  return (
    <>
      <PaginationInput
        className="mr-2"
        content={PAGES}
        page={step}
        onChange={setStep}
      />
      {step === 0 && <Pending />}
      {step === 1 && <NotAvailable />}
      {step === 2 && <Done />}
    </>
  );
};

export default observer(Sales);
