/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect, useCallback } from 'react';

import { PaginationInput } from '../../../../components/widgets';
import { LoadingIndicator } from '../../../../components/utils';
import { get } from '../../../../libs/client';

// import strings from '../../../../localization';

// import EventBuyer from './EventBuyer';
// import Tickets from './Tickets';
// import Transaction from './Transaction';

const Organizer = ({ params }) => {
  const [step, setStep] = useState(0);
  const [sale, setSale] = useState(null);

  const GetOrganizer = useCallback(() => get(`/sales/tickets/${params.id}`)
    .then(({ data }) => setSale(data)).catch((err) => console.log(err)), []);

  useEffect(() => params.id !== 'new' && GetOrganizer(), [GetOrganizer]);

  return (
    sale
      ? (
        <main className="col-md-8 col-xs-12 grid flex-grow-1">
          <span>
            <PaginationInput
              className="mr-2"
              content={['Etkinlik & Satın Alan', 'Biletler', '⚠️ Ödeme Bilgileri ⚠️']}
              page={step}
              onChange={setStep}
            />
          </span>
          {/* <EventBuyer show={step === 0} sale={sale} /> */}
          {/* <Tickets show={step === 1} tickets={sale?.tickets} /> */}
          {/* <Transaction show={step === 2} transaction={sale?.dump} /> */}
        </main>
      )
      : <LoadingIndicator />
  );
};

export default Organizer;
