/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { LoadingIndicator } from '../../../../../components/utils';

import { get, put, post } from '../../../../../libs/client';

import Profile from './Profile';
import Corporate from './Corporate';
import Account from '../../../../../components/forms/Account';
import stores from '../../../../../libs/stores';

const Organizer = ({ params }) => {
  const [data, setData] = useState(null);
  const { register, watch, handleSubmit } = useForm();

  const GetOrganizer = () => get(`/organizers/${params.id}`)
    .then((res) => (res.data ? setData(res.data) : setData({})))
    .catch((err) => console.log(err));

  const CreateOrganizer = (form) => {
    const _form = { ...form, corporate: { ...form.corporate, address: form.address } };
    delete _form.address;
    post('/accounts', {
      ..._form.account,
      type: 'organizer',
      active: true,
      reference_code_count: 4,
      reference_code_type: 'user',
    })
      .then((acc) => {
        console.log(acc);
        post('/organizers', { ..._form, owner: acc.data._id, active: true })
          .then((res) => {
            alert('Organizatör oluşturuldu');
            window.location.replace(`/organizers/${res.data._id}`);
          })
          .catch(() => alert('Organizatör oluşturulurken bir hata oldu'));
      })
      .catch(() => alert('Hesap oluşturulurken bir hata oldu'));
  };

  const UpdateOrganizer = (form, soft) => {
    const _form = { ...form, corporate: { ...form.corporate, address: form.address } };
    delete _form.address;
    put(`/accounts/${data?.owner}`, _form.account)
      .then(() => put(`/organizers/${params.id}`, _form)
        .then(() => {
          alert('Organizatör güncellendi');
          if (soft) {
            GetOrganizer();
          } else {
            window.location.reload();
          }
        })
        .catch(() => alert('Organizatör güncellenirken bir hata oldu')))
      .catch(() => alert('Hesap güncellenirken bir hata oldu'));
  };

  useEffect(() => { if (params.id !== 'new') { GetOrganizer(); } }, []);
  useEffect(() => {
    stores.setAppbar({
      back: '?',
      left: (<h2 className="m-0">Organizatör Bilgileri</h2>),
      right: (
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit(data ? UpdateOrganizer : CreateOrganizer)}
        >
          <i className="fa fa-save mr-2" />
          {data ? 'KAYDET' : 'ORGANİZATÖR OLUŞTUR'}
        </button>
      ),
    });
    stores.setBreadCrumbs([
      { to: '/organizers', title: 'Organizatörler' },
      { to: `/organizers/${data?._id}`, title: data?.name },
    ]);
  }, [data]);

  return (
    (data || params.id === 'new')
      ? (
        <main className="grid">
          <Account id={data?.owner || 'new'} register={register} />
          <Profile
            data={data}
            watch={watch}
            register={register}
            onActiveChange={() => UpdateOrganizer({ active: !data.active }, true)}
          />
          <Corporate
            data={data}
            watch={watch}
            register={register}
            onSave={handleSubmit(data ? UpdateOrganizer : CreateOrganizer)}
          />
        </main>
      )
      : <LoadingIndicator />
  );
};

export default Organizer;
