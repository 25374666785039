/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
// import strings from '../../../localization';
import { post } from '../../../../libs/client';
import { AsyncEntitySelect, CommonInput } from '../../../../components/widgets';

const NewPromotedEvent = ({
  show, onCreate, onClose,
}) => {
  const {
    register, watch, reset, control, handleSubmit,
  } = useForm();
  const [catchphrase, background, foreground] = [watch('catchphrase'), watch('background'), watch('foreground')];

  const CreatePromotedEvent = (form) => {
    post('/app/promotions', { ...form, isVideo: form.foreground.includes('.mp4') })
      .then(() => onCreate())
      .catch(() => alert('Bir sorun oluştu'));
  };

  //   const UpdatePromotedEvent = (form) => {
  //     const _form = { ...form }; _form.event = form.event._id;
  //     put(`/app/promotions/${show._id}`, { ..._form, isVideo: _form.foreground.includes('.mp4') })
  //       .then(() => onUpdate())
  //       .catch(() => alert('Bir sorun oluştu'));
  //   };

  useEffect(() => { if (typeof (show) === 'object') { reset(show); } }, [show]);

  return (
    <div className="promoted-event-dialog center">
      <section>
        <form>
          <div className="d-flex align-items-center">
            <h4 className="fl-1">{typeof (show) === 'object' ? 'Promosyonlu Etkinlik Detayları' : 'Yeni Promosyon'}</h4>
            <button type="button" className="btn btn-danger mb-2" onClick={onClose}><i className="fa fa-close" /></button>
          </div>
          <div className="grid grid-2">
            <div className="promoted-event-window">
              <img
                alt="foreground"
                className="promoted-event-background"
                src={background || 'https://user-images.githubusercontent.com/95723491/211092856-78635012-a15d-43a8-80e1-a2d9ffb07f3f.jpg'}
              />
              <div className="d-flex flex-column align-items-center justify-content-center h-100" style={{ zIndex: 1 }}>
                <div className="d-flex flex-column align-items-center">
                  <p className="text-center h2 text-light mb-2">{catchphrase?.split(' ').join('\n') || 'SLOGAN\nEKLE'}</p>
                  {foreground?.includes('.mp4') ? (
                    <video
                      style={{ width: '75%', aspectRatio: '10/16' }}
                      className="promoted-event-image"
                      autoPlay
                      playsInline
                      muted
                      loop
                      controls
                      src={foreground || 'https://user-images.githubusercontent.com/95723491/211092856-78635012-a15d-43a8-80e1-a2d9ffb07f3f.jpg'}
                    />
                  ) : (
                    <img
                      style={{ width: '75%', aspectRatio: '10/16' }}
                      alt="foreground"
                      className="promoted-event-image"
                      src={foreground || 'https://user-images.githubusercontent.com/95723491/211092856-78635012-a15d-43a8-80e1-a2d9ffb07f3f.jpg'}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <CommonInput
                className="mb-2"
                label="Kampanya Sloganı"
                placeholder="Örn: YAZIN İSTANBULDA"
                defaultValue={show?.catchphrase}
                register={register('catchphrase', { required: true })}
                type="url"
              />
              <CommonInput
                className="mb-2"
                label="Arkaplan Fotoğrafı"
                placeholder="Fotoğraf Linki"
                defaultValue={show?.background}
                register={register('background', { required: true })}
                type="url"
              />
              <CommonInput
                className="mb-2"
                label="Ön Görsel veya Video"
                placeholder="Fotoğraf Linki"
                defaultValue={show?.foreground}
                register={register('foreground', { required: true })}
                type="url"
              />

              <Controller
                control={control}
                name="event"
                render={({ field: { onChange } }) => (
                  <AsyncEntitySelect
                    className="mb-3"
                    extras="recent=true"
                    label="Etkinlik"
                    defaultValue={show?.event}
                    placeholder="Bir etkinlik arayın"
                    register={register('event', { required: true })}
                    entity="events"
                    onChange={onChange}
                  />
                )}
              />
              <button
                type="button"
                className="btn btn-primary w-100 mb-2"
                // onClick={handleSubmit(typeof (show) === 'object' ? UpdatePromotedEvent : CreatePromotedEvent)}
                onClick={handleSubmit(CreatePromotedEvent)}
              >
                KAYDET

              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default NewPromotedEvent;
