/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { observer } from 'remx';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { CommonSelect } from '../../../components/widgets';
import { SalesEventTable } from '../../../components/tables';
import { get } from '../../../libs/client';
import { DecodifySearch, QuerifySearch } from '../../../utils/StringUtils';
// import strings from '../../../localization';

const PAGINATION_LIMIT = 15;

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [search, setSearch] = useState(null);

  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);

  const GetSales = () => get(`/sales/events?page=${page}&sort=desc&limit=${PAGINATION_LIMIT}&${QuerifySearch(DecodifySearch(window.location.search))}`)
    .then(({ data, total }) => { setSales(data); setPages(total); }).catch(console.log);

  useEffect(() => { GetSales(); }, [page]);

  return (
    sales
      ? sales?.length > 0 ? (
        <main className="grid">
          <SalesEventTable
            sales={search || sales}
            onConfirmed={() => GetSales()}
            pager={pages > PAGINATION_LIMIT && (
            <p className="d-flex align-items-center mr-2">
              sayfa
              <CommonSelect
                className="mx-2"
                value={page + 1}
                placeholder={1}
                onChange={({ target }) => setPage(target.value)}
                options={Array.from(Array(Math.floor(pages / PAGINATION_LIMIT)))
                  .map((_, i) => ({ name: i + 2, value: i + 1 }))}
              />
              {`/ ${Math.floor(pages / PAGINATION_LIMIT) + 1}`}
            </p>
            )}
          />
        </main>
      ) : (
        <CenteredInformation
          title="Bekleyen ödemeniz yok"
          subtitle="Etkinliklerinizi satışa açmak için yapmanız gereken ödemeler burada gözükür."
          fixed
        />
      )
      : <LoadingIndicator />
  );
};

export default observer(Sales);
