/* eslint-disable no-underscore-dangle */
import { Link } from 'wouter';
// import dayjs from 'dayjs';

import strings from '../../localization';
import { ConfirmationChip } from '../utils';
import OrganizerSale from '../utils/OrganizerSale';

const SalesEventTable = ({ sales, pager, onRefresh }) => (
  <main className="main table-responsive">
    <h4 className="flex-grow-1 mb-3" id="table-sales">Ödemesi Olmayan Etkinlikler</h4>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Etkinlik Adı</th>
          <th scope="col">Organizatör</th>
          <th scope="col">Mekan Adı</th>
          <th scope="col">ES Events Onayı</th>
          <th scope="col">Mekan Onayı</th>
          <th scope="col">Ödeme Onayı</th>
        </tr>
      </thead>
      <tbody>
        {sales.map((sale) => (
          <tr key={sale._id} id="event-sale-row">
            <td id="event-sale-name">
              <div>
                <img
                  className="mr-4"
                  style={{ objectFit: 'cover' }}
                  alt="event logo"
                  src={sale?.event?.image}
                  width={64}
                  height={64}
                />
                <Link className="text-2-line" to={`/events/${sale?.event?._id}`}>{sale?.event?.name}</Link>
              </div>
            </td>
            <td>
              <div>
                <Link to={`/organizers/${sale?.owner?._id}`}>{sale?.owner?.name}</Link>
              </div>
            </td>
            <td>
              <div>
                <Link to={`/events/${sale?.event?._id}/sales/${sale?._id}`}>{sale?.venue?.name}</Link>
              </div>
            </td>
            <td className="text-center">
              <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                <ConfirmationChip
                  label={sale.vendor_confirmation ? strings[`sale-confirm-${sale?.vendor_confirmation}`] : 'Yeni Satış'}
                  tooltip="Etkinliğinizin satışa başlaması için mekan tarafından onaylanması gerekiyor."
                  status={sale?.vendor_confirmation || 'pending'}
                />
              </div>
            </td>
            <td className="text-center">
              <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                <ConfirmationChip
                  label={sale.venue_confirmation ? strings[`sale-confirm-${sale?.venue_confirmation}`] : 'Yeni Satış'}
                  tooltip="Etkinliğinizin satışa başlaması için ES Events tarafından onaylanması gerekiyor."
                  status={sale?.venue_confirmation || 'pending'}
                />
              </div>
            </td>
            <td className="text-center">
              <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                <OrganizerSale compact sale={sale} onClose={onRefresh} />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="float-right">{pager}</div>
  </main>
);

export default SalesEventTable;
