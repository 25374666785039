import React from 'react';
import ReactDOM from 'react-dom';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';

import { Auth, Panel } from './screens';
import { getToken, setToken } from './libs/storage';
// import './libs/analytics';

import './index.css';
import { DecodifySearch } from './utils/StringUtils';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('tr');

const Ordos = () => {
  const { token } = DecodifySearch(window.location.search);
  if (token) { setToken(token); }
  ReactDOM.render(getToken() ? <Panel /> : <Auth />, document.getElementById('app'));
};

Ordos();
