/* eslint-disable no-underscore-dangle */

const BillingsTable = ({ data }) => (
  <main className="main p-0 table-responsive">
    <section>
      <div className="d-flex align-items-center p-3">
        <h4 className="flex-grow-1">{data?.length > 0 ? `Toplam ${data.length} fatura bilgisi var` : 'Hiç fatura bilgisi yok'}</h4>
      </div>
    </section>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Adı</th>
          <th scope="col">TCKN</th>
          <th scope="col">E-Mail</th>
          <th scope="col">Adres</th>
          <th scope="col" className="text-center">Aktiflik</th>
        </tr>
      </thead>
      <tbody>
        {data.map((entity, i) => (
          <tr key={entity._id}>
            <td>{i + 1}</td>
            <td>{entity?.name}</td>
            <td>{entity?.ssn}</td>
            <td>{entity?.email}</td>
            <td>{`${entity?.address?.neighborhood} mah. ${entity?.address?.street} cad. No: ${entity?.address?.no}\n${entity?.address?.district} / ${entity?.address?.city}`}</td>
            <td>
              <button
                disabled
                type="button"
                className={`w-100 btn btn-sm btn-${!entity?.device?.deletedAt ? 'success' : 'danger'}`}
                // onClick={() => onClickActive(entity)}
              >
                {!entity?.device?.deletedAt ? 'Evet' : 'Hayır'}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </main>
);

export default BillingsTable;
