import { useState, useEffect } from 'react';

import { Link } from 'wouter';
import { LoadingIndicator } from '../../../components/utils';
import { ProviderTile } from '../../../components/tiles';
// import strings from '../../../localization';
import { get } from '../../../libs/client';
import stores from '../../../libs/stores';

const Onboarding = () => {
  const [providers, setProviders] = useState(null);

  const GetProviders = () => get('/organizers/providers/me')
    .then(({ data }) => setProviders(data)).catch((err) => console.log(err));

  useEffect(() => {
    GetProviders();
    stores.setAppbar({
      left: (
        <h2 className="m-0">ES Events Organizatör Kayıt Ekranı</h2>
      ),
      right: (
        <Link to="/providers/new">
          <button
            type="button"
            className="btn btn-primary"
          >
            <i className="fa fa-plus mr-2" />
            YENİ ÖDEME SAĞLAYICI
          </button>
        </Link>
      ),
    });
  }, []);

  return (
    providers ? (
      <main className="grid">
        <section className="grid grid-3">
          {providers.map((provider) => <ProviderTile provider={provider} />)}
        </section>
        <hr />
      </main>
    )
      : <LoadingIndicator />
  );
};

export default Onboarding;
