import { CommonInput } from '../../../../components/widgets';
// import strings from '../../../../localization';

const IyzicoForm = ({ register, provider }) => (
  <section className="grid grid-2">
    <CommonInput
      label="Iyzico API Key"
      register={register('credentials.iyzicoApiKey')}
      defaultValue={provider?.credentials?.iyzicoApiKey}
    />
    <CommonInput
      label="Iyzico Secret Key"
      register={register('credentials.iyzicoSecret')}
      defaultValue={provider?.credentials?.iyzicoSecret}
    />
  </section>
);

export default IyzicoForm;
