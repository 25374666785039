/* eslint-disable jsx-a11y/label-has-associated-control */

const CommonInput = ({
  register, placeholder, defaultValue, prepend, disabled, style, value,
  append, multiline, small, label, className, type, size, tooltip, min, max,
  multilineHeight = 5, labelClassName, length, limit, onKeyDown, textAlign = 'left',
}) => (
  <fieldset disabled={disabled} className={`form-group mb-0 ${className || ''}`} style={style}>
    {label && (
    <label className={`d-flex align-items-end ${labelClassName}`}>
      <span className="flex-grow-1">{label}</span>
      {limit && (
      <span
        className="ml-2 font-weight-normal"
        style={{ color: length > limit ? 'red' : 'black' }}
      >
        {`(${length || '0'}/${limit || 140})`}
      </span>
      )}
    </label>
    )}
    {tooltip && (
      <span className="info pointer ml-2">
        <i className="fa fa-question-circle" />
        <span className="info-text">
          {tooltip}
        </span>
      </span>
    )}
    <div className="input-group">
      {prepend && (
      <div className="input-group-prepend">
        <span className="input-group-text">{prepend}</span>
      </div>
      )}
      {multiline ? (
        <textarea
          className={`form-control form-control-${size}`}
          {...{ value }}
          {...{ defaultValue }}
          {...{ placeholder }}
          {...register}
          style={{ minHeight: `${multilineHeight}rem` }}
          onKeyDown={onKeyDown}
        />
      ) : (
        <input
          type={type || 'text'}
          className={`form-control form-control-${size}`}
          style={{ textAlign }}
          {...{ value }}
          {...{ defaultValue }}
          {...{ placeholder }}
          {...{ min, max }}
          {...register}
          onKeyDown={onKeyDown}
        />
      )}
      {append && (
      <div className="input-group-append">
        <span className="input-group-text">{append}</span>
      </div>
      )}
    </div>
    {small && <small className="form-text text-muted">{small}</small>}
  </fieldset>
);

export default CommonInput;
