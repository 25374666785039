const AsyncButton = ({
  icon, text, type, loading, onClick, btnClass,
}) => (
  <button
    className={`btn btn-${type} position-relative ${btnClass}`}
    style={{ minWidth: '3rem' }}
    type="button"
    disabled={loading}
    {...{ onClick }}
  >
    <section style={{ opacity: loading ? 0 : 1 }}>
      <i
        style={{ marginRight: text ? '0.25rem' : 0 }}
        className={`fa fa-${icon}`}
      />
      {text}
    </section>
    {loading && (
      <section className="position-absolute center">
        <span className="spinner-border spinner-border-sm" />
      </section>
    )}
  </button>
);

export default AsyncButton;
