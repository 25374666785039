/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { CommonInput, CommonSelect } from '../../../components/widgets';
import { AccountsTable } from '../../../components/tables';
import { DecodifySearch, QuerifySearch } from '../../../utils/StringUtils';
import { get, put } from '../../../libs/client';
import stores from '../../../libs/stores';
// import strings from '../../../localization';

const PAGINATION_LIMIT = 15;

const Search = ({ disabled, query, onSearch }) => {
  const [defaults] = useState(DecodifySearch(query || window.location.search));
  const { register, reset, handleSubmit } = useForm({ defaultValues: defaults });

  useEffect(() => onSearch(defaults), []);

  return (
    <main className="main grid grid-slim align-items-end" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr' }}>
      <CommonInput
        label="Kullanıcı Adı"
        placeholder="İsimle arayın"
        register={register('name')}
        disabled={disabled}
      />
      <CommonInput
        label="E-Mail Adresi"
        placeholder="E-Mail ile arayın"
        register={register('email')}
        disabled={disabled}
      />
      <CommonInput
        label="Telefon No"
        placeholder="Telefon ile arayın"
        register={register('phone')}
        disabled={disabled}
      />
      <CommonInput
        label="Kayıt Başlangıç"
        type="date"
        disabled={disabled}
        register={register('start')}
      />
      <CommonInput
        label="Kayıt Bitiş"
        type="date"
        disabled={disabled}
        register={register('end')}
      />
      <CommonSelect
        label="Türü"
        placeholder="Bir tür seçin"
        disabled={disabled}
        defaultValue={defaults.active}
        options={[
          { value: 'admin', name: 'Admin' },
          { value: 'organizer', name: 'Organizatör' },
          { value: 'venue', name: 'Mekan' },
          { value: 'user', name: 'Kullanıcı' },
          { value: '', name: 'Hepsi' },
        ]}
        register={register('type')}
      />
      <CommonSelect
        label="Aktif mi?"
        placeholder="Bir statü seçin"
        disabled={disabled}
        defaultValue={defaults.active}
        options={[
          { value: 'true', name: 'Evet' },
          { value: 'false', name: 'Hayır' },
          { value: '', name: 'Hepsi' },
        ]}
        register={register('active')}
      />
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary btn-normal flex-grow-1"
          disabled={disabled}
          onClick={handleSubmit(onSearch)}
        >
          <i className="fa fa-search" />
        </button>
        {query && (
          <button
            type="button"
            className="btn btn-danger btn-normal flex-grow-1 ml-2"
            onClick={() => { onSearch({}); reset(null); }}
          >
            <i className="fa fa-trash" />
          </button>
        )}
      </div>
    </main>
  );
};

const Accounts = () => {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState(QuerifySearch(DecodifySearch(window.location.search)));

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const GetData = () => get(`/accounts?page=${total}&limit=${PAGINATION_LIMIT}&${query || ''}`)
    .then((res) => { setData(res.data); setPages(res.total); })
    .catch(console.log);

  const UpdateActive = (form) => put(`/accounts/${form._id}`, { active: !form.active })
    .then(() => { alert('Hesap güncellendi'); GetData(true); })
    .catch(console.log);

  useEffect(() => { GetData(); }, [query, total]);
  useEffect(() => { stores.setAppbar({ left: (<h2 className="m-0">Tüm Kayıtlı Hesaplar</h2>) }); }, []);

  return (
    data
      ? (
        <main className="grid">
          <Search
            onSearch={(q) => {
              setQuery(QuerifySearch(q));
              setTotal(0);
            }}
            query={query}
          />
          {data?.length > 0
            ? (
              <AccountsTable
                data={data}
                total={pages}
                index={(PAGINATION_LIMIT * total)}
                onClickActive={(form) => {
                  if (window.confirm(form.active
                    ? 'Hesabı kapatmak istediğinize emin misiniz?'
                    : 'Hesabı açmak istediğinize emin misiniz?')) {
                    UpdateActive(form);
                  }
                }}
                pager={(
                  <p className="d-flex align-items-center mr-2">
                    sayfa
                    <CommonSelect
                      className="mx-2"
                      value={total + 1}
                      placeholder={1}
                      onChange={({ target }) => setTotal(target.value)}
                      options={Array.from(Array(Math.floor(Math.abs(pages - 1) / PAGINATION_LIMIT)))
                        .map((_, i) => ({ name: i + 2, value: i + 1 }))}
                    />
                    {`/ ${Math.floor(Math.abs(pages - 1) / PAGINATION_LIMIT) + 1}`}
                  </p>
                )}
              />
            ) : (
              <CenteredInformation
                title={query ? 'Aradığınız Hesap Yok' : 'Hiç Kayıtlı Hesap Yok'}
                subtitle="Yeni hesap oluşturun"
                fixed
              />
            )}
        </main>
      )
      : <LoadingIndicator />);
};

export default Accounts;
