/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { LoadingIndicator } from '../../../../../components/utils';

import { get, put, post } from '../../../../../libs/client';

import Profile from './Profile';
import Address from './Address';
import Account from '../../../../../components/forms/Account';
import stores from '../../../../../libs/stores';
import { PaginationInput } from '../../../../../components/widgets';
import VenueSeating from './Seating';

const PAGES = ['Mekan Bilgileri', 'Oturma Planları'];

const Venue = ({ params }) => {
  const [page, setPage] = useState(0);
  const [data, setEntity] = useState(null);
  const [categories, setCategories] = useState(null);
  const { register, watch, handleSubmit } = useForm();

  const GetVenue = () => get(`/venues/${params.id}`)
    .then((res) => (res.data ? setEntity(res.data) : setEntity({})))
    .catch((err) => console.log(err));

  const GetVenueCategories = () => get('/venues/categories')
    .then((res) => setCategories(res.data))
    .catch((err) => console.log(err));

  const CreateVenue = (form) => post('/accounts', {
    ...form.account,
    reference_code_count: 0,
    reference_code_type: 'venue',
    type: 'venue',
    active: true,
  })
    .then((acc) => {
      console.log(acc);
      post('/venues', { ...form, owner: acc.data._id, active: true })
        .then((res) => {
          alert('Mekan oluşturuldu');
          window.location.replace(`/venues/${res.data._id}`);
        })
        .catch(() => alert('Mekan oluşturulurken bir hata oldu'));
    })
    .catch(() => alert('Hesap oluşturulurken bir hata oldu'));

  // const CreateVenue = (form) => console.log(form);

  const UpdateVenue = (form, soft) => {
    put(`/accounts/${data?.owner._id}`, form.account)
      .then(() => put(`/venues/${params.id}`, form)
        .then(() => {
          alert('Mekan güncellendi');
          if (soft) {
            GetVenue();
          } else {
            window.location.reload();
          }
        })
        .catch(() => alert('Mekan güncellenirken bir hata oldu')))
      .catch(() => alert('Hesap güncellenirken bir hata oldu'));
  };

  useEffect(() => {
    console.log(params.id);
    if (params.id !== 'new') {
      GetVenue();
    }
    GetVenueCategories();
  }, []);

  useEffect(() => {
    stores.setAppbar({
      back: '?',
      left: (<h2 className="m-0">Mekan Bilgileri</h2>),
      right: (
        <button
          type="button"
          disabled={page !== 0}
          className="btn btn-primary"
          onClick={handleSubmit(data ? UpdateVenue : CreateVenue)}
        >
          <i className="fa fa-save mr-2" />
          {data ? 'KAYDET' : 'MEKAN OLUŞTUR'}
        </button>
      ),
    });
    stores.setBreadCrumbs([
      { to: '/venues', title: 'Mekanlar' },
      { to: `/venues/${data?._id}`, title: data?.name },
      { to: `/venues/${data?._id}`, title: PAGES[page] },
    ]);
  }, [data, page]);

  return (
    ((data || params.id === 'new') && categories)
      ? (
        <main className="grid">
          {data && (
            <PaginationInput
              className="mr-2"
              content={PAGES}
              onChange={setPage}
              page={page}
            />
          )}
          {page === 0 && (
            <>
              <Account id={data?.owner._id || 'new'} register={register} noReferences />
              <Profile
                data={data}
                watch={watch}
                register={register}
                categories={categories}
                onActiveChange={() => UpdateVenue({ active: !data.active }, true)}
              />
              <Address
                data={data}
                watch={watch}
                register={register}
              />
            </>
          )}
          {(page === 1 && data) && (
            <VenueSeating params={params} />
          )}
        </main>
      )
      : <LoadingIndicator />
  );
};

export default Venue;
