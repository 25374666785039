import { Switch, Route, Redirect } from 'wouter';

import Register from './Register';
import Login from './Login';

const Auth = () => (
  <main className="d-flex justify-content-center p-2">
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Redirect to="/login" />
    </Switch>
  </main>
);

export default Auth;
