/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */

import Address from '../../../../../components/forms/Address';
import { CommonInput } from '../../../../../components/widgets';

const Account = ({ data, register, watch }) => (
  <>
    <form>
      <h4>Kurumsal Bilgiler</h4>
      <CommonInput
        label="Şirket Ünvanı"
        placeholder="eg: Sunset Events Etkinlik Organizasyon A.Ş."
        register={register('corporate.legalName', { required: true })}
        defaultValue={data?.corporate?.legalName}
      />
      <section className="grid grid-2">
        <CommonInput
          label="Vergi Dairesi"
          placeholder="eg: ŞİŞLİ"
          register={register('corporate.taxRegistry', { required: true })}
          defaultValue={data?.corporate?.taxRegistry}
        />
        <CommonInput
          label="Vergi Numarası"
          placeholder="eg: 1234567890"
          register={register('corporate.taxNo', { required: true })}
          defaultValue={data?.corporate?.taxNo}
        />
      </section>
    </form>
    <form>
      <h4>Şirket Adresi</h4>
      <Address data={data?.corporate} watch={watch} field="corporate" register={register} />
    </form>
  </>
);

export default Account;
