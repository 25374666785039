/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { observer } from 'remx';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { ContractTile } from '../../../components/tiles';
// import strings from '../../../localization';
import { get } from '../../../libs/client';
import stores from '../../../libs/stores';

const Contracts = () => {
  const [contracts, setContracts] = useState(null);

  const GetContracts = async () => {
    const tos = (await get('/organizers/contracts/hibilet/tos')).data;
    const eula = (await get('/organizers/contracts/hibilet/eula')).data;
    const gdpr = (await get('/organizers/contracts/hibilet/gdpr')).data;
    setContracts([tos, eula, gdpr]);
  };

  useEffect(() => {
    GetContracts();
    stores.setAppbar({
      left: (
        <h2 className="m-0">ES Events Uygulama Sözleşmeleri</h2>
      ),
    });
  }, []);

  return (
    contracts ? (
      contracts.length > 0 ? (
        <main className="grid">
          <section className="grid grid-3">
            {contracts.map((contract) => <ContractTile contract={contract} />)}
          </section>
          <hr />
        </main>
      )
        : (
          <CenteredInformation
            title="Satış sözleşmesi yok"
            subtitle="Satış yapmak için kullanıcılarınızın okuyabileceği bir sözleşme eklemeniz gerekiyor."
            fixed
          />
        )
    )
      : <LoadingIndicator />
  );
};

export default observer(Contracts);
