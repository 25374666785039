/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { CommonInput, CommonSelect } from '../../../components/widgets';
import { SalesTable } from '../../../components/tables';
import { get, put } from '../../../libs/client';
import { DecodifySearch, QuerifySearch } from '../../../utils/StringUtils';
import stores from '../../../libs/stores';
// import strings from '../../../localization';

const PAGINATION_LIMIT = 15;

const SaleSearch = ({ disabled, onSearch, onClear }) => {
  const [clear, setClear] = useState(false);
  const { register, reset, handleSubmit } = useForm();

  return (
    <main className="main grid grid-slim align-items-end" style={{ gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr 1fr 0.5fr ${clear ? '0.5fr' : ''}` }}>
      <CommonInput
        label="Etkinlik Adı"
        placeholder="Etkinlik adıyla arayın"
        register={register('event')}
        disabled={disabled}
      />
      <CommonInput
        label="Mekan"
        placeholder="Mekan adıyla arayın"
        register={register('venue')}
        disabled={disabled}
      />
      <CommonInput
        label="Başlangıç"
        type="date"
        disabled={disabled}
        register={register('start')}
      />
      <CommonInput
        label="Bitiş"
        type="date"
        disabled={disabled}
        register={register('end')}
      />
      <CommonInput
        label="Bilet referansı"
        className="fl-1"
        placeholder="Bilet referansı ile ara"
        register={register('ticketref')}
        disabled={disabled}
      />
      <CommonSelect
        label="Durumu"
        placeholder="Bir statü seçin"
        disabled={disabled}
        defaultValue="success"
        options={[
          { value: 'success', name: 'Başarılı' },
          { value: 'pending', name: 'Bekliyor' },
          { value: 'refund', name: 'İade' },
        ]}
        register={register('status')}
      />
      <button
        type="button"
        className="btn btn-primary btn-normal"
        onClick={() => { setClear(true); handleSubmit(onSearch)(); }}
        disabled={disabled}
      >
        <i className="fa fa-search mr-2" />
      </button>
      {clear && (
        <button
          type="button"
          className="btn btn-danger btn-normal"
          onClick={() => { setClear(false); reset(); onClear(); }}
          disabled={disabled}
        >
          <i className="fa fa-trash mr-2" />
        </button>
      )}
    </main>
  );
};

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [search, setSearch] = useState(null);

  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);

  const GetSales = () => get(`/sales?page=${page}&sort=desc&limit=${PAGINATION_LIMIT}&${QuerifySearch(DecodifySearch(window.location.search))}`)
    .then(({ data, total }) => { setSales(data); setPages(total); }).catch(console.log);

  const SearchSales = (query) => query && get(`/sales?page=0&limit=1000&${query}`)
    .then(({ data, total }) => { setSearch(data); setPages(total); }).catch(console.log);

  const Refund = (sale) => window.confirm('Seçtiğiniz satınalım iade edilecektir.')
   && put(`/sales/${sale._id}/refund`)
     .then(() => {
       alert(`Biletler iade edildi.\n${sale.reference} referans kodu ile Sanal POS'unuzu kontrol etmeyi unutmayın!`);
       window.open(sale.redir, '_blank');
       if (search) SearchSales();
       else GetSales();
     })
     .catch(console.log);

  useEffect(() => { if (search) { SearchSales(page); } else { GetSales(); } }, [page]);

  useEffect(() => {
    stores.setAppbar({
      left: (
        <h2 className="m-0">Tüm Satınalımlar</h2>
      ),
    });
  }, [sales]);

  return (
    sales
      ? sales?.length > 0 ? (
        <>
          <SaleSearch
            onSearch={(query) => SearchSales(QuerifySearch(query))}
            onClear={() => setSearch(null)}
            disabled={sales?.length < 1}
          />
          <main className="grid">
            <SalesTable
              title="En Son Gerçekleşen Satınalımlar"
              sales={search || sales}
              pager={pages > (search ? 1000 : PAGINATION_LIMIT) && (
              <p className="d-flex align-items-center mr-2">
                sayfa
                <CommonSelect
                  className="mx-2"
                  value={page + 1}
                  placeholder={1}
                  onChange={({ target }) => setPage(target.value)}
                  options={Array.from(Array(Math.floor(pages / (search ? 1000 : PAGINATION_LIMIT))))
                    .map((_, i) => ({ name: i + 2, value: i + 1 }))}
                />
                {`/ ${Math.floor(pages / (search ? 1000 : PAGINATION_LIMIT)) + 1}`}
              </p>
              )}
              onRefund={Refund}
            />
          </main>

        </>
      ) : (
        <CenteredInformation
          title="Hiç satınalım yok"
          subtitle="Kullanıcılar bilet satın alınca burada gözükür."
          fixed
        />
      )
      : <LoadingIndicator />
  );
};

export default Sales;
