import { useState } from 'react';
import { Link } from 'wouter';
import { useForm } from 'react-hook-form';

import { AsyncButton, CommonInput } from '../../components/widgets';

import strings from '../../localization';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const onRegister = (form) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      console.log(form);
    }, 1500);
  };

  return (
    <div className="jumbotron col-md-4">
      <h1>{strings['auth-register-title']}</h1>
      <section className="row">
        <CommonInput
          placeholder={strings['placeholder-firstname']}
          register={register('email')}
          className="col-md-6 mb-2"
        />
        <CommonInput
          placeholder={strings['placeholder-lastname']}
          register={register('phone')}
          className="col-md-6 mb-2"
        />
      </section>
      <section className="row">
        <CommonInput
          placeholder={strings['placeholder-email']}
          append={<i className="fa fa-envelope" />}
          register={register('email')}
          className="col-md-12 mb-2"
        />
      </section>
      <section className="row">
        <CommonInput
          placeholder={strings['placeholder-phone']}
          append={<i className="fa fa-phone" />}
          register={register('phone')}
          className="col-md-12 mb-2"
        />
      </section>
      <section className="row">
        <section className="col-md-12">
          <AsyncButton
            type="primary"
            btnClass="col-md-12 mb-2"
            text={strings['action-register']}
            onClick={handleSubmit(onRegister)}
            {...{ loading }}
          />
          <Link className="btn text-right col-md-12 text-center" to="/login">
            {strings['action-login']}
          </Link>
        </section>
      </section>
    </div>
  );
};

export default Register;
