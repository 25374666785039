/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { observer } from 'remx';
import { SortableItem, swapArrayPositions } from 'react-sort-list';

import dayjs from 'dayjs';
import { CenteredInformation, LoadingIndicator } from '../../../../components/utils';
// import strings from '../../../localization';
import { del, get, put } from '../../../../libs/client';
import stores from '../../../../libs/stores';
import NewPromotedEvent from './New';
// import NewPromotedEvent from './New';

const PromotedItem = ({ item, onEdit, onDelete }) => (
  <div>
    <div className="d-flex align-items-center justify-content-end">
      {/* <p className="flex-grow-1">{`# ${item.id}`}</p> */}
      {/* <p onClick={onEdit} className="btn btn-link p-0 mr-2">Düzenle</p> */}
      <p onClick={onDelete} className="btn btn-link p-0 text-danger">Kaldır</p>
    </div>
    {item?.foreground?.includes('.mp4') ? (
      <video
        style={{ width: '100%', aspectRatio: '10/16' }}
        className="promoted-event-image"
        autoPlay
        muted
        loop
        controls
        src={item?.foreground || 'https://user-images.githubusercontent.com/95723491/197223028-9cf70141-df52-431d-b02d-b5acdc6251c7.mp4'}
      />
    ) : (
      <img
        style={{ width: '100%', aspectRatio: '10/16' }}
        alt="foreground"
        className="promoted-event-image"
        src={item?.foreground || 'https://user-images.githubusercontent.com/95723491/197223197-6d8d346e-87ab-4c79-92e3-bc8e698b2651.jpg'}
      />
    )}
    <p className="text-center">{item?.event?.name}</p>
  </div>
);

const PromotedEvents = () => {
  const [promotions, setPromotions] = useState(null);
  const [show, setShow] = useState(false);

  const [order, setOrder] = useState([]);

  function swap(dragIndex, dropIndex) {
    const swappedTodos = swapArrayPositions(order, dragIndex, dropIndex);

    setOrder([...swappedTodos]);
  }

  const GetPromotions = () => get('/app/promotions')
    .then(({ data }) => setPromotions(data)).catch((err) => console.log(err));

  const DeletePromotion = ({ _id }) => del(`/app/promotions/${_id}`)
    .then(() => GetPromotions()).catch((err) => console.log(err));

  const UpdatePromotions = () => {
    Promise.all(order.map((o, i) => put(`/app/promotions/${o._id}`, { id: i })))
      .then(() => alert('Promosyonlu etkinlikler güncellendi'))
      .catch(() => alert('Bir hata oluştu'));
  };

  useEffect(() => {
    if (promotions) {
      setOrder(promotions.map((p, i) => ({ ...p, id: p.priority || (i + 1) })));
    }
  }, [promotions]);

  useEffect(() => { GetPromotions(); }, []);
  useEffect(() => {
    stores.setAppbar({
      left: (<h2 className="m-0">Promosyonlu Etkinlikler</h2>),
      right: (
        <>
          <button type="button" className="btn btn-primary mr-2" onClick={UpdatePromotions}>
            <i className="fa fa-save mr-2" />
            KAYDET
          </button>
          <button type="button" className="btn btn-primary" onClick={() => setShow(true)}>
            <i className="fa fa-plus mr-2" />
            YENİ PROMOSYON
          </button>
        </>
      ),
    });
  }, [order]);

  return (
    <>
      {promotions ? (
        promotions.length > 0 ? (
          <main className="grid">
            <section className="grid grid-6">
              {order?.map((item) => (
                <SortableItem items={order} id={item.id} key={item._id} swap={swap}>
                  <PromotedItem
                    onDelete={() => DeletePromotion(item)}
                    // onUpdate={() => GetPromotions()}
                    onEdit={() => setShow(item)}
                    item={item}
                  />
                </SortableItem>
              ))}
            </section>
            <hr />
          </main>
        )
          : (
            <CenteredInformation
              title="Promosyonlu Etkinlik Yok"
              subtitle="Uygulamanın ana sayfasında gözükmesi için promosyonlu etkinlik oluşturun"
              fixed
            />
          )
      )
        : <LoadingIndicator />}
      {show && (
        <NewPromotedEvent
          show={show}
          onClose={() => setShow(false)}
          onCreate={() => { setShow(false); GetPromotions(); }}
        />
      )}
    </>
  );
};

export default observer(PromotedEvents);
