/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'wouter';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';

import 'quill/dist/quill.snow.css';

import { LoadingIndicator } from '../../../components/utils';
import { CommonInput } from '../../../components/widgets';
import {
  get, put, post,
} from '../../../libs/client';
import stores from '../../../libs/stores';
// import strings from '../../../localization';

// Quill.register(Quill.import('attributors/style/direction'), true);
// Quill.register(Quill.import('attributors/style/align'), true);

const Contract = ({ params }) => {
  const [contract, setContract] = useState(null);
  const [, setLocation] = useLocation();

  const {
    register, control, reset, handleSubmit,
  } = useForm();

  const GetContract = useCallback(() => get(`/organizers/contracts/${params.id}`)
    .then(({ data }) => setContract(data))
    .catch((err) => console.log(err)), []);

  const UpdateContract = (form) => put(`/organizers/contracts/${params.id}`, form)
    .then(() => { alert('Sözleşme güncellendi.'); setLocation('/contracts'); })
    .catch(() => alert('Bir hata oluştu.'));

  const CreateContract = (form) => post('/organizers/contracts/me', form)
    .then(() => { alert('Sözleşme oluşturuldu.'); setLocation('/contracts'); })
    .catch(() => alert('Bir hata oluştu.'));

  useEffect(() => params.id !== 'new' && GetContract(), [GetContract]);
  useEffect(() => params.id !== 'new' && reset({ ...contract }), contract);

  useEffect(() => stores.setAppbar({
    left: (
      <h2 className="m-0">{contract ? `${contract?.name} detayları` : 'Yeni Sözleşme' }</h2>
    ),
    right: (
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleSubmit(params.id === 'new' ? CreateContract : UpdateContract)}
      >
        <i className="fa fa-save mr-2" />
        {params.id === 'new' ? 'SÖZLEŞME OLUŞTUR' : 'KAYDET'}
      </button>
    ),
  }), [contract]);

  return (
    <form>
      {/* Requires opacity trick to preload word processor. */}
      {!(contract || params.id === 'new') && <LoadingIndicator />}
      <div className="grid flex-grow-1" style={{ opacity: (contract || params.id === 'new') ? 1 : 0 }}>
        <h4>Sözleşme Bilgileri</h4>
        <CommonInput
          label="Sözleşmenin Adı"
          register={register('name')}
          placeholder="Örn: Satış Sözeşmesi"
          defaultValue={contract?.name}
        />
        <div>
          <p className="mb-2">Sözleşmenin içeriği</p>
          <div className="w-100 flex-grow-1">
            <Controller
              name="content"
              control={control}
              rules={{ required: 'Lütfen boş bırakmayın.' }}
              render={({ field }) => (
                <ReactQuill
                  {...field}
                  theme="snow"
                  defaultValue={contract?.name}
                  placeholder="Sözleşme detaylarını girin."
                  onChange={field.onChange}
                  className="d-flex flex-column"
                />
              )}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default Contract;
