/* eslint-disable no-underscore-dangle */
import { Link } from 'wouter';
// import dayjs from 'dayjs';

import { Thousandify } from '../../utils/StringUtils';
import strings from '../../localization';
import { ConfirmationChip } from '../utils';
import { put } from '../../libs/client';

const colors = {
  success: 'success',
  pending: 'warning',
  refunded: 'danger',
};

const currencies = {
  TRY: '₺',
  USD: '$',
  EUR: '€',
};

const SalesEventTable = ({
  sales, pager, onPayment, onConfirmed,
}) => {
  const UpdateSale = (params, form) => put(`/events/${params?.event?._id}/sales/${params?.sale?._id}`, form)
    .then(() => { alert('Satış bilgileri güncellendi'); onConfirmed(); });
    // .catch(() => alert('Satış bilgileri güncellenirken bir hata oldu'));

  const ConfirmSale = (sale) => {
    UpdateSale(sale, { vendor_confirmation: (sale?.sale?.vendor_confirmation === 'confirmed' ? 'pending' : 'confirmed') });
  };

  return (
    <main className="main table-responsive">
      <h4 className="flex-grow-1 mb-3" id="table-sales">Bekleyen Ödemelerim</h4>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Etkinlik Adı</th>
            <th scope="col">Organizatör</th>
            <th scope="col">Mekan Adı</th>
            <th scope="col">Toplam</th>
            {/* <th scope="col">Tarihi</th> */}
            <th scope="col">ES Events Onayı</th>
            <th scope="col">Mekan Onayı</th>
            <th scope="col" className="text-center">Durumu</th>
          </tr>
        </thead>
        <tbody>
          {sales.map((sale) => (
            <tr key={sale._id} id="event-sale-row">
              <td id="event-sale-name">
                <div>
                  <img
                    className="mr-4"
                    style={{ objectFit: 'cover' }}
                    alt="event logo"
                    src={sale?.event?.image}
                    width={64}
                    height={64}
                  />
                  <Link className="text-2-line" to={`/events/${sale?.event?._id}`}>{sale?.event?.name}</Link>
                </div>
              </td>
              <td>
                <div>
                  <Link to={`/organizers/${sale?.owner?._id}`}>{sale?.owner?.name}</Link>
                </div>
              </td>
              <td>
                <div>
                  <Link to={`/events/${sale?.event?._id}/sales/${sale?.sale?._id}`}>{sale?.sale?.venue?.name}</Link>
                </div>
              </td>
              <td>
                <div>
                  {sale?.amount ? `${Thousandify((sale?.amount).toFixed(2))} ${currencies[sale?.currency] || currencies.EUR}` : 'N/A'}
                </div>
              </td>
              {/* <td>
                <div>
                  {`${dayjs.utc(sale.createdAt).format('DD.MM.YYYY\nHH:mm')}`}
                </div>
              </td> */}
              <td className="text-center">
                <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                  <ConfirmationChip
                    onClick={() => (sale?.sale?.venue_confirmation === 'confirmed' ? ConfirmSale(sale) : alert('Mekan onaylamadan etkinliği onaylayamazsınız'))}
                    label={sale?.sale?.vendor_confirmation ? strings[`sale-confirm-${sale?.sale?.vendor_confirmation}`] : 'Yeni Satış'}
                    tooltip="Etkinliğinizin satışa başlaması için ES Events tarafından onaylanması gerekiyor."
                    status={sale?.sale?.vendor_confirmation || 'pending'}
                  />
                </div>
              </td>
              <td className="text-center">
                <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                  <ConfirmationChip
                    label={sale?.sale?.venue_confirmation ? strings[`sale-confirm-${sale?.sale?.venue_confirmation}`] : 'Yeni Satış'}
                    tooltip="Etkinliğinizin satışa başlaması için mekan tarafından onaylanması gerekiyor."
                    status={sale?.sale?.venue_confirmation || 'pending'}
                  />
                </div>
              </td>
              <td>
                <div>
                  <button
                    type="button"
                    disabled
                    className={`m-0 w-100 btn btn-${colors[sale?.status]}`}
                    onClick={() => onPayment(sale)}
                  >
                    {strings[`event-sale-${sale?.status}`]}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="float-right">{pager}</div>
    </main>
  );
};

export default SalesEventTable;
