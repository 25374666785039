/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEffect, useState } from 'react';
import { BillingsTable } from '../../../../../components/tables';
import { get } from '../../../../../libs/client';

const Billings = ({ owner }) => {
  const [data, setData] = useState([]);

  const GetData = () => {
    get(`/users/${owner}/billings`)
      .then((res) => setData(res.data))
      .catch(console.log);
  };

  useEffect(() => { GetData(); }, []);

  return (<BillingsTable data={data} />);
};

export default Billings;
