import { useState, useEffect } from 'react';

import CommonInput from './CommonInput';
import strings from '../../localization';

const GalleryInput = ({ data, disabled, onChange }) => {
  const [images, setImages] = useState(data || []);
  const [input, setInput] = useState('');

  useEffect(() => {
    onChange({ target: { value: images } });
  }, [images]);

  return (
    <div className="grid">
      <div className="grid grid-6 grid-3-m">
        {images.map((i) => (
          <div key={i}>
            <div className="card">
              {!disabled && (
                <button
                  type="button"
                  label="delete"
                  className="position-absolute btn btn-danger fa fa-times"
                  style={{ top: '0.5rem', right: '0.5rem' }}
                  onClick={() => setImages(images.filter((j) => j !== i))}
                />
              )}
              <img src={i} className="card-img-top" style={{ objectFit: 'cover', height: '10rem' }} alt="..." />
            </div>
          </div>
        ))}
      </div>
      <CommonInput
        disabled={disabled}
        label={strings['placeholder-event-picture']}
        placeholder={strings['placeholder-picture-input']}
        register={{
          onChange: ({ target }) => setInput(target.value),
        }}
        // onKeyDown={(e) => e.key === 'Enter' && setImages([...images, input])}
        append={(
          <button
            type="button"
            className="btn btn-link btn-sm p-0"
            // disabled={!input}
            disabled={disabled}
            onClick={() => setImages([...images, input])}
          >
            <i className="fa fa-plus mr-2" />
            {strings['action-upload']}
          </button>
        )}
      />
    </div>

  );
};

export default GalleryInput;
