/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import {
  Switch, Route, Redirect, useLocation,
} from 'wouter';
import dayjs from 'dayjs';
import { observer } from 'remx';
import 'dayjs/locale/tr';
import { Sidebar, Appbar } from '../../components/navigation';

import stores from '../../libs/stores';

import Home from './Home';

import Onboarding from './Onboarding';

import Events from './Events';
import Event from './Events/Event';
import EventSale from './Events/EventSale';

import Sales from './Sales';
import Sale from './Sales/Sale';

import Providers from './Providers';
import Provider from './Providers/Provider';

import Contracts from './Contracts';
import Contract from './Contracts/Contract';

import Promoted from './App/Promoted';

import Accounts from './Accounts';

import Organizers from './Accounts/Organizers';
import Organizer from './Accounts/Organizers/Organizer';

import Venues from './Accounts/Venues';
import Venue from './Accounts/Venues/Venue';

import Users from './Accounts/Users';
import User from './Accounts/Users/User';
import Payments from './Payments';

import { LoadingIndicator } from '../../components/utils';
import { get } from '../../libs/client';
import { setToken } from '../../libs/storage';

dayjs.locale('tr');

const Panel = () => {
  const [location] = useLocation();

  useEffect(() => get('/accounts/me')
    .then(({ data }) => stores.setAccount(data))
    .catch(() => setToken('')),
  []);
  useEffect(() => {
    if (!location.includes('events') && location.split('/').length > 1) {
      stores.setBreadCrumbs([]);
    }
  }, [location]);

  return (stores.getAccount() ? (
    <div className="app-wrapper">
      {!stores.getAccount().new && <Sidebar />}
      <div className="grid app-content">
        <Appbar />
        <Switch>
          {stores.getAccount().new && <Route path="/" component={Onboarding} />}
          <Route path="/home" component={Home} />

          <Route path="/events" component={Events} />
          <Route path="/events/:id" component={Event} />
          <Route path="/events/:eid/sales/:id" component={EventSale} />

          <Route path="/reports" component={Sales} />
          <Route path="/reports/:id" component={Sale} />

          {/* <Route path="/providers" component={Providers} /> */}
          <Route path="/admin-provider" component={Provider} />

          <Route path="/admin-contracts" component={Contracts} />
          <Route path="/admin-contracts/:id" component={Contract} />

          <Route path="/payments" component={Payments} />

          <Route path="/promoteds" component={Promoted} />
          {/* <Route path="/app" component={Contract} /> */}

          <Route path="/accounts" component={Accounts} />
          <Route path="/accounts/:id" component={Accounts} />

          <Route path="/organizers" component={Organizers} />
          <Route path="/organizers/:id" component={Organizer} />

          <Route path="/venues" component={Venues} />
          <Route path="/venues/:id" component={Venue} />

          <Route path="/users" component={Users} />
          <Route path="/users/:id" component={User} />

          <Redirect to="/home" />
        </Switch>
      </div>
    </div>
  ) : <LoadingIndicator />);
};

export default observer(Panel);
