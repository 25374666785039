/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
// import { EventTicketsTable } from '../../../../components/tables';

import { EventTicketsTable } from '../../../../../components/tables';
import { SeatingEngine } from '../../../../../components/utils';
import { CommonInput, CommonSelect } from '../../../../../components/widgets';
import { post, put } from '../../../../../libs/client';
import { DecodifySearch } from '../../../../../utils/StringUtils';

const Session = ({
  sale, params, session, plans, disabled, onRemove,
}) => {
  const {
    register, control, watch, reset, setValue, handleSubmit,
  } = useForm();

  const fieldArray = useFieldArray({ control, name: 'tickets' });

  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(session.active);
  const [start, end, tickets, seating] = [watch('start'), watch('end'), watch('tickets'), watch('seating_plan')];
  const [showSeating, setShowSeating] = useState(false);

  const CheckStock = (form) => {
    if ((Math.max(...form.tickets.map((t) => Number(t.stock))) <= Number(form.tickets_stock - (data?.sold || 0)))) {
      return true;
    } return alert('Bilet kategorilerinin stoğu, kalan bilet sayısından fazla olamaz.');
  };
  const CheckSoldOut = (form) => {
    let hasSoldOuts = false;
    form.tickets.forEach((t, ti) => { hasSoldOuts = hasSoldOuts || (Number(t?.stock) <= Number(session?.tickets[ti]?.sold)); });
    if (hasSoldOuts) {
      if (window.confirm('Stokları tükenmiş biletler var, kaydetmek istediğinize emin misiniz?')) {
        return CheckStock(form);
      } return false;
    } return CheckStock(form);
  };

  const CreateSession = (form) => {
    if (form?.tickets?.length > 0) {
      if (CheckSoldOut(form)) {
        post(`/events/${params.eid}/sales/${params.id}/sessions`, form)
          .then((instance) => { alert('Seans oluşturuldu.'); setData(instance.data); })
          .catch(() => alert('Seans oluşturulurken bir hata oldu.'));
      }
    } else { alert('En az bir bilet kategorisi girmeniz gerekiyor'); }
  };

  const UpdateSession = (form) => {
    if (CheckSoldOut(form)) {
      put(`/events/${params.eid}/sales/${params.id}/sessions/${data._id}`, form)
        .then(() => alert('Seans güncellendi.'))
        .catch(() => alert('Seans güncellenirken bir hata oldu.'));
    }
  };

  // const CreateSession = (form) => console.log(form);
  // const UpdateSession = (form) => console.log(form);

  const SwitchSessionStatus = () => {
    put(`/events/${params.eid}/sales/${params.id}/sessions/${session._id}`, { active: !active })
      .then((res) => { alert('Seans güncellendi.'); setActive(res.data.active); })
      .catch(() => alert('Seans güncellenirken bir hata oldu.'));
  };

  useEffect(() => {
    if (session) {
      reset({
        seating_plan: session?.seating_plan,
        seating_distribution: session?.seating_distribution,
        tickets: session?.tickets,
      });
      setOpen((DecodifySearch(window.location.search)?.session === session?._id) || (session._id.includes('new')));
      setData(session);
    }
  }, [session]);

  useEffect(() => {
    setActive(data?.active);
  }, [data]);

  useEffect(() => {
    setValue('seating_plan', session?.seating_plan?._id);
  }, [plans]);

  useEffect(() => data?._id?.includes('new') && setValue('seating_distribution', []), [seating]);

  return (
    data && (
      <form>
        <details open={open}>
          <summary className="d-flex align-items-center">
            <h4 className="flex-grow-1 m-0">
              {`${(() => {
                if (start) {
                  return dayjs.utc(start).format('D MMM, HH:mm');
                }
                if (data?.start) {
                  return dayjs.utc(data?.start).format('D MMM, HH:mm');
                } return 'Başlangıç Tarihi Seçin';
              })()} - ${(() => {
                if (end) {
                  return dayjs.utc(end).format('D MMM, HH:mm');
                } if (data?.end) {
                  return dayjs.utc(data?.end).format('D MMM, HH:mm');
                } return 'Bitiş Tarihi Seçin';
              })()}`}
            </h4>
            {data._id.includes('new') && (
              <button
                type="button"
                className="btn btn-danger mr-2"
                onClick={onRemove}
              >
                <i className="fa fa-trash mr-2" />
                Seansı Sil
              </button>
            )}
            {!data._id.includes('new') && (
              <button
                type="button"
                className={`btn btn-${active ? 'danger' : 'success'} mr-2`}
                onClick={() => SwitchSessionStatus()}
              >
                <i className={`fa fa-${active ? 'ban' : 'check'} mr-2`} />
                {active ? 'SEANSI GİZLE' : 'SEANSI LİSTELE'}
              </button>
            )}
          </summary>
          <section className="grid grid-3 mt-2 mb-3">
            {(() => {
              const min = dayjs.utc().startOf('day').add(7, 'days').format('YYYY-MM-DDTHH:mm');
              return (
                <>
                  <CommonInput
                    disabled
                    label="Seans Başlangıç Tarihi"
                    defaultValue={(() => {
                      if (data?.start) {
                        return dayjs.utc(data?.start).format('YYYY-MM-DDTHH:mm');
                      } return null;
                    })()}
                    register={register('start', {
                      validate: (value) => dayjs.utc(value).isAfter(dayjs.utc()),
                    })}
                    min={min}
                    type="datetime-local"
                    onChange={({ target }) => reset({ end: dayjs.utc(target.value).add(4, 'hours').format('YYYY-MM-DDTHH:mm') })}
                  />
                  <CommonInput
                    disabled
                    label="Seans Bitiş Tarihi"
                    register={register('end', {
                      validate: (value) => dayjs.utc(value).isAfter(dayjs.utc(start)),
                    })}
                    defaultValue={(() => {
                      if (data?.end) {
                        return dayjs.utc(data?.end).format('YYYY-MM-DDTHH:mm');
                      } return null;
                    })()}
                    min={(() => {
                      if (end) {
                        return dayjs.utc(start).format('YYYY-MM-DDTHH:mm');
                      } return dayjs.utc(min).format('YYYY-MM-DDTHH:mm');
                    })()}
                    type="datetime-local"
                  />
                </>
              );
            })()}
            <CommonSelect
              label="Oturma Planı"
              placeholder={(() => {
                if (!data._id.includes('new')
                && sale?.vendor_confirmation !== 'pending'
                && sale?.venue_confirmation !== 'pending') {
                  return data?.seating_plan?.name || 'Ayakta Etkinlik';
                }
                return 'Ayakta Etkinlik';
              })()}
              options={plans.map(({ _id, name }) => ({ value: _id, name }))}
              disabled={
                !data._id.includes('new')
                && sale?.vendor_confirmation !== 'pending'
                && sale?.venue_confirmation !== 'pending'
              }
              register={register('seating_plan')}
            />
            <CommonInput
              disabled
              label="Toplam Bilet Adedi"
              placeholder="örn: 100"
              type="number"
              defaultValue={data?.tickets_stock}
              register={register('tickets_stock', { required: true })}
            />
            <CommonInput
              label="Satılan Bilet Adedi"
              placeholder="Biletleriniz satılınca güncellenir"
              disabled
              defaultValue={data?.sold || ''}
            />
            <CommonInput
              label="Kalan Bilet Adedi"
              placeholder="Biletleriniz satılınca güncellenir"
              defaultValue={data?.sold ? (data?.tickets_stock - data?.sold) : ''}
              disabled
            />
          </section>
          <EventTicketsTable
            fieldArray={fieldArray}
            disabled
            session={session}
            register={register}
            watch={watch}
          />
          {showSeating && (
            <Controller
              control={control}
              name="seating_distribution"
              render={({ field: { onChange, value } }) => (
                <SeatingEngine
                  session={session}
                  params={params}
                  tickets={tickets}
                  value={value}
                  plan={seating || session?.seating_plan}
                  onClose={() => setShowSeating(false)}
                  onSelect={onChange}
                />
              )}
            />
          )}
        </details>
      </form>
    )
  );
};

export default Session;
