/* eslint-disable jsx-a11y/label-has-associated-control */
import dayjs from 'dayjs';
import { CommonInput } from '../../../../../components/widgets';

const Profile = ({ data, onActiveChange }) => (
  <form>
    <h4>Temel Bilgiler</h4>
    <section className={`grid grid-${data ? '4' : '3'}`}>
      <CommonInput
        disabled
        label="Cinsiyeti"
        placeholder="eg: Erkek"
        defaultValue={data?.gender}
      />
      <CommonInput
        disabled
        label="Doğum Tarihi"
        placeholder="eg: 27 Aralık 2022"
        defaultValue={dayjs.utc(data?.birthday).format('DD MMMM YYYY')}
      />
      <CommonInput
        disabled
        label="Yaşı"
        placeholder="eg: 19"
        defaultValue={dayjs.utc().diff(dayjs.utc(data?.birthday), 'years')}
      />
      {data && (
        <div className="mb-0 d-flex flex-column justify-content-end">
          <label>Kullanıcı aktif mi?</label>
          <button
            disabled
            type="button"
            onClick={onActiveChange}
            className={`w-100 btn btn-${data?.active ? 'success' : 'danger'}`}
          >
            {data?.active ? 'Evet' : 'Hayır'}
          </button>
        </div>
      )}
    </section>
  </form>
);

export default Profile;
