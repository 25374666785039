/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { observer } from 'remx';
import { Link, useLocation } from 'wouter';
import stores from '../../libs/stores';

const usePrevLocation = () => {
  const [location] = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);
  const lastSavedLocation = useRef(null); // temp buffer
  useEffect(() => {
    setPrevLocation(lastSavedLocation.current);
    lastSavedLocation.current = location;
  }, [location]);

  return prevLocation;
};

const Appbar = () => {
  const delta = usePrevLocation();
  return (
    <nav className="appbar">
      {stores.getAppbar()?.back && (
      <Link to={delta + stores.getAppbar()?.back}>
        <i
          className="text-primary p-0 fa fa-arrow-left mr-4 pointer"
          style={{ fontSize: '1.5rem', fontWeight: 'normal' }}
        />
        {/* <p>{delta + stores.getAppbar()?.back}</p> */}
      </Link>
      )}
      <div className="m-0 d-flex flex-column fl-1">
        {stores.getAppbar()?.left}
        <div className="d-flex">
          {(stores.getBreadCrumbs() || []).map(({ title, to }, i) => (
            title ? (
              <Fragment key={title}>
                <Link to={to}>{title}</Link>
                {(i < stores.getBreadCrumbs().length - 1) && <p className="text-primary mx-1">/</p>}
              </Fragment>
            ) : null
          ))}
        </div>
      </div>
      {stores.getAppbar()?.right}
      {/* Seperator */}
      <button
        type="button"
        className="btn btn-secondary mobile-only ml-2"
        onClick={() => stores.setShowSidebar(!stores.getShowSidebar())}
      >
        <i className="fa fa-bars" />
      </button>
    </nav>
  );
};

export default observer(Appbar);
