/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { LoadingIndicator } from '../../../../../components/utils';

import { get, put, post } from '../../../../../libs/client';

import Profile from './Profile';
import Devices from './Devices';
import Billings from './Billings';
import Sales from './Sales';

import Account from '../../../../../components/forms/Account';
import stores from '../../../../../libs/stores';
import { PaginationInput } from '../../../../../components/widgets';
import ReaderAuthCode from '../../../Events/Event/ReaderAuthCode';

const PAGES = ['Kullanıcı Bilgileri', 'Cihazlar', 'Fatura Bilgileri', 'Satın Alımlar'];

const User = ({ params }) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState(null);
  const [accountAuthCode, setAccountAuthCode] = useState(null);
  const { register, handleSubmit } = useForm();

  const GetUser = () => get(`/users/${params.id}`)
    .then((res) => (res.data ? setData(res.data) : setData({})))
    .catch((err) => console.log(err));

  const LoginAs = () => get(`/auth/login/${data?.owner?._id}`)
    .then((res) => setAccountAuthCode(res.data))
    .catch((err) => console.log(err));

  const CreateUser = (form) => post('/accounts', {
    ...form.account,
    type: 'organizer',
    active: true,
    reference_code_count: 4,
    reference_code_type: 'user',
  })
    .then((acc) => {
      console.log(acc);
      post('/users', { ...form, owner: acc.data._id, active: true })
        .then((res) => {
          alert('Organizatör oluşturuldu');
          window.location.replace(`/users/${res.data._id}`);
        })
        .catch(() => alert('Organizatör oluşturulurken bir hata oldu'));
    })
    .catch(() => alert('Hesap oluşturulurken bir hata oldu'));

  const UpdateUser = (form) => {
    put(`/accounts/${data?.owner?._id}`, form.account)
      .then(() => { alert('Kullanıcı güncellendi'); window.location.reload(); })
      .catch((e) => alert(JSON.stringify(e)));
  };

  // const UpdateOrganizer = (form) => console.log(form);

  useEffect(() => { if (params.id !== 'new') { GetUser(); } }, []);
  useEffect(() => {
    stores.setAppbar({
      back: '?',
      left: (<h2 className="m-0">Kullanıcı Bilgileri</h2>),
      right: (
        <>
          <button
            type="button"
            disabled={page !== 0}
            className="btn btn-primary mr-2"
            onClick={LoginAs}
          >
            <i className="fa fa-share mr-2" />
            GİRİŞ YAP
          </button>
          <button
            type="button"
            disabled={page !== 0}
            className="btn btn-primary"
            onClick={handleSubmit(data ? UpdateUser : CreateUser)}
          >
            <i className="fa fa-save mr-2" />
            KAYDET
          </button>
        </>
      ),
    });
    stores.setBreadCrumbs([
      { to: '/users', title: 'Kullanıcılar' },
      { to: `/users/${data?._id}`, title: `${data?.owner?.firstName} ${data?.owner?.lastName}` },
      { to: `/users/${data?._id}`, title: PAGES[page] },
    ]);
  }, [data, page]);

  return (
    (data || params.id === 'new')
      ? (
        <main className="grid">
          <PaginationInput
            className="mr-2"
            content={PAGES}
            onChange={setPage}
            page={page}
          />
          {page === 0 && (
            <>
              <Account id={data?.owner?._id || 'new'} register={register} />
              <Profile data={data} />
            </>
          )}
          {page === 1 && (<Devices owner={data._id} />)}
          {page === 2 && (<Billings owner={data._id} />)}
          {page === 3 && (<Sales owner={data._id} />)}
          <ReaderAuthCode
            title="Kullanıcı Hesapları İçin QR Kod"
            subtitle={'ES Events uygulamasına girin\nGiriş Yap butonuna 10 saniye basılı tutun\nQR Kodu okutun'}
            code={accountAuthCode}
            onClose={() => setAccountAuthCode(null)}
          />
        </main>
      )
      : <LoadingIndicator />
  );
};

export default User;
