/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';

const DevicesTable = ({ data }) => (
  <main className="main p-0 table-responsive">
    <section>
      <div className="d-flex align-items-center p-3">
        <h4 className="flex-grow-1">{data?.length > 0 ? `Toplam ${data.length} cihaz var` : 'Hiç cihaz yok'}</h4>
      </div>
    </section>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Cihaz Adı</th>
          <th scope="col">GSM</th>
          <th scope="col">OS</th>
          <th scope="col">Versiyon</th>
          <th scope="col">Üretici</th>
          <th scope="col">Kayıt Tarihi</th>
          <th scope="col" className="text-center">Aktiflik</th>
        </tr>
      </thead>
      <tbody>
        {data.map((entity, i) => (
          <tr key={entity._id}>
            <td>{i + 1}</td>
            <td>{entity?.device?.deviceName}</td>
            <td>{entity?.device?.carrier}</td>
            <td>{entity?.device?.os}</td>
            <td>{entity?.device?.osVersion}</td>
            <td>{entity?.device?.manufacturer}</td>
            <td>{dayjs.utc(entity.createdAt).format('DD MMM YYYY, HH:mm')}</td>
            <td>
              <button
                disabled
                type="button"
                className={`w-100 btn btn-sm btn-${!entity?.device?.deletedAt ? 'success' : 'danger'}`}
                // onClick={() => onClickActive(entity)}
              >
                {!entity?.device?.deletedAt ? 'Evet' : 'Hayır'}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </main>
);

export default DevicesTable;
