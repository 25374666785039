/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { Link } from 'wouter';

const AccountsTable = ({
  data, total, index, pager, onClickActive,
}) => (
  <main className="main table-responsive">
    <h4 className="flex-grow-1 mb-3">{data?.length > 0 ? `Toplam ${total} hesap var` : 'Hiç hesap yok'}</h4>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Kullanıcı Türü</th>
          <th scope="col">Kullanıcı Adı</th>
          <th scope="col">E-Posta Adresi</th>
          <th scope="col">Telefon Numarası</th>
          <th scope="col">Kayıt Tarihi</th>
          <th scope="col" className="text-center">Aktiflik</th>
        </tr>
      </thead>
      <tbody>
        {data.map((entity, i) => (
          <tr key={entity._id}>
            <td>{index + i + 1}</td>
            <td>{entity.type}</td>
            <td><Link to={`/accounts/${entity?._id}`}>{entity.name}</Link></td>
            <td>{entity.email}</td>
            <td>{entity.phone}</td>
            <td>{dayjs.utc(entity.createdAt).format('DD MMM YYYY, HH:mm')}</td>
            <td>
              <button
                type="button"
                onClick={() => onClickActive(entity)}
                className={`w-100 btn btn-sm btn-${entity.active ? 'success' : 'danger'}`}
              >
                {entity.active ? 'Evet' : 'Hayır'}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="float-right">{pager}</div>
  </main>
);

export default AccountsTable;
