import { useEffect } from 'react';

import { post } from '../../libs/client';
import { setToken } from '../../libs/storage';
import { LoadingIndicator } from '../../components/utils';

const AttemptLogin = async (email, _data) => {
  const code = window.prompt(`Lütfen tek kullanımlık kodunuzu girin (${_data.code})`);
  if (code) {
    return post('/auth/login', { email, code })
      .then(({ data }) => { setToken(data.token); })
      .catch(() => {
        alert('Girdiğiniz kod yanlış');
        AttemptLogin(email);
      });
  }
  return window.location.reload();
};

const Login = () => {
  useEffect(() => {
    const email = window.prompt('Lütfen ES Events organizatör hesabınızın e-posta adresini girin');
    post('/auth/request', { email, type: 'admin' })
      .then(({ data }) => {
        AttemptLogin(email, data);
      })
      .catch(() => alert('Kod gönderilirken bir hata oldu'));
  }, []);

  return (
    <LoadingIndicator />
  );
};

export default Login;
