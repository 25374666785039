import { useState } from 'react';

import { PaginationInput } from '../../../../components/widgets';
import { DecodifySearch } from '../../../../utils/StringUtils';

// import strings from '../../../../localization';

import BasicInfo from './BasicInfo';
import Sessions from './Sessions';

const PAGES = ['Temel Bilgiler', 'Etkinlik Seansları'];

const EventSale = ({ params }) => {
  const [step, setStep] = useState(Number(DecodifySearch(window.location.search)?.step) || 0);

  const [venue, setVenue] = useState(null);
  const [sale, setSale] = useState(null);

  return (
    <main className="grid">
      <PaginationInput
        className="mr-2"
        content={PAGES}
        page={step}
        onChange={setStep}
      />
      <BasicInfo
        show={step === 0}
        page={PAGES[step]}
        step={step}
        params={params}
        onVenueChange={setVenue}
        onSaleChange={setSale}
      />
      <Sessions
        show={step === 1}
        params={params}
        venue={venue}
        sale={sale}
      />
    </main>
  );
};

export default EventSale;
