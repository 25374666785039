/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'wouter';
import { useForm } from 'react-hook-form';

import { LoadingIndicator } from '../../../../components/utils';
import { CommonInput, CommonSelect } from '../../../../components/widgets';
import {
  get, put, post, del,
} from '../../../../libs/client';

import PAYMENT_PROVIDERS from '../../../../constants/payment-providers.json';

import FormIyzico from './FormIyzico';
import FormWeepay from './FormWeepay';
import FormCraftgate from './FormCraftgate';

import stores from '../../../../libs/stores';

// import strings from '../../../localization';

const Provider = ({ params }) => {
  const { register, watch, handleSubmit } = useForm();
  const [provider, setProvider] = useState(null);
  const [selectedProvider] = [watch('provider')];
  const [, setLocation] = useLocation();

  const GetProvider = useCallback(() => get(`/organizers/providers/${params.id}`)
    .then(({ data }) => setProvider(data))
    .catch((err) => console.log(err)), []);

  const UpdateProvider = (form) => put(`/organizers/providers/${params.id}`, form)
    .then(() => {
      alert('Updated provider.');
      setLocation('/providers');
    }).catch(() => alert('An error occurred while updating the contract.'));

  const CreateProvider = (form) => post('/organizers/providers/me', form)
    .then(() => {
      alert('Created provider.');
      setLocation('/providers');
    }).catch(() => alert('An error occurred while creating the contract.'));

  useEffect(() => params.id !== 'new' && GetProvider(), [GetProvider]);

  useEffect(() => stores.setAppbar({
    left: (
      <h2 className="m-0">{provider ? `${provider?.name} detayları` : 'Yeni Ödeme Sağlayıcı' }</h2>
    ),
    right: (
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleSubmit(params.id === 'new' ? CreateProvider : UpdateProvider)}
      >
        <i className="fa fa-save mr-2" />
        {params.id === 'new' ? 'SAĞLAYICI OLUŞTUR' : 'KAYDET'}
      </button>
    ),
  }), [provider]);

  const ProviderForm = () => {
    switch (selectedProvider || provider?.provider) {
      case 'iyzico':
        return <FormIyzico register={register} provider={provider} />;
      case 'weepay':
        return <FormWeepay register={register} provider={provider} />;
      case 'craftgate':
        return <FormCraftgate register={register} provider={provider} />;
      default:
        return <p>Lütfen bir sağlayıcı seçin.</p>;
    }
  };

  return (
    (provider || params.id === 'new') ? (
      <main className="grid">
        <form>
          <h4> Sağlayıcı Bilgileri</h4>
          <section className="grid grid-3">
            <CommonInput
              label="Sağlayıcı Adı"
              register={register('name', { required: true })}
              placeholder="eg: Genel Satış"
              defaultValue={provider?.name}
            />
            <CommonInput
              label="Bağlı Banka"
              register={register('bank', { required: true })}
              placeholder="eg: Akbank"
              defaultValue={provider?.bank}
            />
            <CommonSelect
              label="Ödeme Sağlayıcı Servis"
              placeholder="Lütfen bir sağlayıcı seçin"
              options={PAYMENT_PROVIDERS}
              register={register('provider')}
              defaultValue={provider?.provider}
            />
          </section>
        </form>
        <form>
          <h4>Sağlayıcı Detayları</h4>
          {ProviderForm()}
        </form>
      </main>
    ) : <LoadingIndicator />
  );
};

export default Provider;
