/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react';

import { get } from '../../../../../libs/client';

import Session from './Session';

const Sessions = ({
  // sale,
  venue, onSessionSelect,
  show, params,
}) => {
  const [sessions, setSessions] = useState([]);
  const [plans, setPlans] = useState([]);

  const GetSessions = useCallback(() => get(`/events/${params.eid}/sales/${params.id}/sessions`)
    .then(({ data }) => setSessions(data))
    .catch((err) => console.log(err)), []);

  const GetSeatingPlans = () => get(`/venues/${venue}/seating`)
    .then(({ data }) => setPlans(data))
    .catch((err) => console.log(err));

  useEffect(() => venue && GetSeatingPlans(venue), [venue]);
  useEffect(() => GetSessions(), []);

  return (
    <section className="grid" style={{ display: show ? 'grid' : 'none' }}>
      {sessions.map((session) => (
        <Session
          key={session?._id || Math.random()}
          session={session}
          params={params}
          plans={plans}
          disabled
          onOpen={() => onSessionSelect(session)}
          onRemove={() => setSessions(sessions.filter(({ _id }) => _id !== session._id))}
        />
      ))}
      {/* <button
        type="button"
        className="btn btn-primary"
        disabled={params.id === 'new' || (sale?.vendor_confirmation === 'confirmed' && sale?.venue_confirmation === 'confirmed')}
        onClick={() => setSessions([...sessions, {
          _id: `new${sessions.length}`,
          tickets: [],
        }])}
      >
        <i className="fa fa-plus mr-2" />
        {params.id === 'new' ? 'LÜTFEN ÖNCE SATIŞ OLUŞTURUN' : 'YENİ SEANS EKLE'}
      </button> */}
    </section>
  );
};

export default Sessions;
