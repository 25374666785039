/* eslint-disable no-underscore-dangle */
// import dayjs from 'dayjs';
import { Link } from 'wouter';

const OrganizersTable = ({
  data, total, index, pager, onClickActive, onLoginAs,
}) => (
  <main className="main table-responsive">
    <h4 className="flex-grow-1 flex-grow-1 mb-3">{data?.length > 0 ? `Toplam ${total} organizatör var` : 'Hiç organizatör yok'}</h4>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Organizatör Adı</th>
          <th scope="col">Kullanıcı Hesabı</th>
          <th scope="col">E-Posta Adresi</th>
          <th scope="col">Telefon</th>
          {/* <th scope="col">Kayıt Tarihi</th> */}
          <th scope="col" className="text-center">Aktiflik</th>
          <th scope="col" className="text-center">Giriş</th>
        </tr>
      </thead>
      <tbody>
        {data.map((entity, i) => (
          <tr key={entity._id}>
            <td><div>{index + i + 1}</div></td>
            <td>
              <div>
                <img className="mr-2 rounded" alt="logo" src={entity?.logo} width={64} height={64} />
                <Link to={`/organizers/${entity?._id}`}>{entity.name}</Link>
              </div>
            </td>
            <td><div><Link to={`/accounts/${entity?.owner?._id}`}>{entity?.owner?.name}</Link></div></td>
            <td><div>{entity?.owner?.email}</div></td>
            <td><div>{entity?.owner?.phone}</div></td>
            {/* <td><div>{dayjs.utc(entity.createdAt).format('DD MMM YYYY, HH:mm')}</div></td> */}
            <td>
              <div>
                <button
                  type="button"
                  onClick={() => onClickActive(entity)}
                  className={`w-100 btn btn-sm btn-${entity.owner.active ? 'success' : 'danger'}`}
                >
                  {entity.owner.active ? 'Evet' : 'Hayır'}
                </button>
              </div>
            </td>
            <td>
              <div>
                <button type="button" onClick={() => onLoginAs(entity?.owner?._id)} className="w-100 btn btn-sm btn-primary">
                  <i className="fa fa-share" />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="float-right">{pager}</div>
  </main>
);

export default OrganizersTable;
