/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */

import { Controller } from 'react-hook-form';
import {
  ArtistInput, CommonInput, CommonSelect, GalleryInput, ImageInput,
} from '../../../../components/widgets';

import strings from '../../../../localization';

const EventBasicInfo = ({
  show, register, event, control, categories, watch,

}) => {
  const [image, qr, desc, notes] = [watch('image'), watch('qr'), watch('desc'), watch('notes')];

  return (
    <section className="grid" style={{ display: show ? 'grid' : 'none' }}>
      <form>
        <h4>Temel Bilgiler</h4>
        <section className="grid grid-2">
          <CommonInput
            disabled
            label="Etkinlik Adı"
            placeholder="eg: Sunset Events"
            register={register('name')}
            defaultValue={event?.name}
          />
          <CommonSelect
            label="Etkinlik Kategorisi"
            placeholder={strings[`category-${event?.category?.handle}`]}
            register={register('category')}
            defaultValue={event?.category}
            options={categories}
            domain="category"
            disabled
          />
        </section>
      </form>
      <form>
        <h4>Etkinlik Açıklaması</h4>
        <section className="grid grid-2">
          <CommonInput
            disabled
            label="Türkçe Açıklama"
            register={register('desc.tr', { maxLength: 300 })}
            defaultValue={event?.desc?.tr}
            length={desc?.tr?.length}
            limit={300}
            multiline
          />
          <CommonInput
            disabled
            label="İngilizce Açıklama"
            register={register('desc.en', { maxLength: 300 })}
            defaultValue={event?.desc?.en}
            length={desc?.en?.length}
            limit={300}
            multiline
          />
        </section>
      </form>
      <form>
        <h4>Etkinlik Görselleri</h4>
        <section className="grid grid-2">
          <ImageInput
            disabled
            register={register('image')}
            defaultValue={event?.image}
            watch={image}
            resolution="1024x1024"
            ratio="1:1"
            placeholder="Uygulamada, ana sayfada gösterilecek olan görsel"
            label="Ana Etkinlik Görseli"
          />
          <ImageInput
            disabled
            register={register('qr')}
            defaultValue={event?.qr}
            watch={qr}
            resolution="512x512"
            ratio="1:1"
            placeholder="Uygulamada, biletlerin üzerinde gösterilecek olan görsel"
            label="QR Kod Görseli"
          />
        </section>
        <Controller
          control={control}
          name="gallery"
          render={({ field: { onChange } }) => (
            <GalleryInput disabled data={event?.gallery} onChange={onChange} />
          )}
        />
      </form>
      <form>
        <h4>Etkinlik Sanatçıları</h4>
        <Controller
          control={control}
          name="artists"
          render={({ field: { onChange } }) => (
            <ArtistInput disabled data={event?.artists} onChange={onChange} />
          )}
        />
      </form>
      {(event?.notes?.tr || event?.notes?.en) && (
        <form>
          <h4 className="d-flex align-items-end">
            <span className="flex-grow-1">Etkinlik Bildirileri</span>
            <span className="h6 ml-2">(Sadece etkinlik hakkında acil bilgiler için kullanın!)</span>
          </h4>
          <section className="grid grid-2">
            <CommonInput
              disabled
              label="Türkçe Bildiriler"
              register={register('notes.tr', { maxLength: 140 })}
              defaultValue={event?.notes?.tr}
              length={notes?.en?.length}
              limit={140}
              multiline
            />
            <CommonInput
              disabled
              label="İngilizce Bildirileri"
              register={register('notes.en', { maxLength: 140 })}
              defaultValue={event?.notes?.en}
              length={notes?.en?.length}
              limit={140}
              multiline
            />
          </section>
        </form>
      )}
    </section>
  );
};

export default EventBasicInfo;
