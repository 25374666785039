/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect, useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { useLocation } from 'wouter';
import { PaginationInput } from '../../../../components/widgets';
import { LoadingIndicator } from '../../../../components/utils';
import {
  get, post, put,
} from '../../../../libs/client';

// import strings from '../../../../localization';

import BasicInfo from './BasicInfo';
import Sales from './Sales';

import stores from '../../../../libs/stores';
import ReaderAuthCode from './ReaderAuthCode';
import { DecodifySearch } from '../../../../utils/StringUtils';

const PAGES = ['Temel Bilgiler', 'Etkinlik Satışları'];

const Event = ({ params }) => {
  const [, setLocation] = useLocation();
  const [step, setStep] = useState(Number(DecodifySearch(window.location.search)?.step) || 0);

  const [event, setEvent] = useState(null);
  const [categories, setCategories] = useState([]);
  const [readerAuthCode, setReaderAuthCode] = useState();

  const {
    control, watch, register, handleSubmit, reset, setValue,
  } = useForm({ defaultValues: event });

  const { fields, append, remove } = useFieldArray({ control, name: 'tickets' });

  const [name] = [watch('name')];

  const GetEvent = useCallback(() => get(`/events/${params.id}`)
    .then(({ data }) => setEvent(data)).catch((err) => console.log(err)), []);
  const GetEventCategories = useCallback(() => get('/events/categories')
    .then(({ data }) => setCategories(data)).catch((err) => console.log(err)), []);

  const CreateEvent = (form) => post('/events/', form)
    .then(() => {
      alert('Event created.');
      setLocation('/events');
    })
    .catch(() => alert('Etkinlik oluştururken bir hata oluştu'));

  const UpdateEvent = (form) => {
    put(`/events/${params.id}`, form)
      .then(() => { alert('Etkinlik güncellendi'); GetEvent(); })
      .catch(() => alert('Etkinliği güncellerken bir hata oluştu'));
  };

  const UpdateReaderAuthCode = () => {
    put(`/events/${params.id}/readerAuthCode`)
      .then(({ data }) => setReaderAuthCode((data)))
      .catch(() => alert('Kapı operasyon kodu oluşturulurken bir hata oldu.'));
  };

  useEffect(() => {
    if (params.id !== 'new') { GetEvent(); }
    GetEventCategories();
  }, [GetEvent, GetEventCategories]);

  useEffect(() => {
    stores.setAppbar({
      back: '?',
      left: (
        <h2 className="m-0">{event ? `${event?.name} detayları` : (name ? `${name} detayları` : 'Yeni Etkinlik')}</h2>
      ),
      right: (
        <div>
          {params.id !== 'new' && (
          <>
            <button
              type="button"
              className={`btn btn-${event?.active ? 'danger' : 'success'} mr-2`}
              onClick={() => UpdateEvent({ active: !event.active })}
            >
              <i className={`fa fa-${event?.active ? 'ban' : 'check'} mr-2`} />
              {event?.active ? 'ETKİNLİĞİ GİZLE' : 'ETKİNLİĞİ LİSTELE'}
            </button>
            {/* <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => UpdateReaderAuthCode()}
            >
              <i className="fa fa-qrcode mr-2" />
              KAPI OPERASYON
            </button> */}
          </>
          )}
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(params.id === 'new' ? CreateEvent : UpdateEvent)}
            disabled={step === 2}
          >
            <i className="fa fa-save mr-2" />
            {params.id === 'new' ? 'ETKİNLİK OLUŞTUR' : 'KAYDET'}
          </button> */}
        </div>
      ),
    });
    stores.setBreadCrumbs([
      { to: '/events', title: 'Etkinlikler' },
      { to: `/events/${event?._id || 'new'}`, title: event?.name || 'Yeni' },
      { to: `/events/${event?._id}`, title: PAGES[step] },
    ]);
  }, [event, step, name]);

  return (
    (event || params.id === 'new')
      ? (
        <main className="grid">
          <PaginationInput
            className="mr-2"
            content={PAGES}
            page={step}
            onChange={setStep}
          />
          <BasicInfo
            show={step === 0}
            register={register}
            event={event}
            control={control}
            categories={categories}
            params={params}
            watch={watch}
          />
          {/* <Descriptions
            show={step === 1}
            register={register}
            event={event}
            watch={watch}
          /> */}
          <Sales
            show={step === 1}
            register={register}
            event={event}
            params={params}
            reset={reset}
            setValue={setValue}
            fields={fields}
            append={append}
            remove={remove}
          />
          <ReaderAuthCode
            title="Kapı operasyonları için QR Kod"
            subtitle={'ES Events uygulamasına kullanıcı olarak giriş yapın.\nEtkinliği arayın ve etkinlik fotoğrafına 10 saniye basılı tutun.\nEkrandaki QR kodu uygulamaya okutun.'}
            code={readerAuthCode}
            onClose={() => setReaderAuthCode(null)}
          />
        </main>
      )
      : <LoadingIndicator />
  );
};

export default Event;
