/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
import { Link } from 'wouter';
import dayjs from 'dayjs';

import strings from '../../localization';
import { ConfirmationChip } from '../utils';

const EventSalesTable = ({ event }) => (
  <main className="main p-0 table-responsive">
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col" className="text-center">#</th>
          <th scope="col">Mekan Adı</th>
          <th scope="col">Tarih Aralığı</th>
          <th scope="col">Seanslar</th>
          <th scope="col" className="text-center">ES Events Onayı</th>
          <th scope="col" className="text-center">Mekan Onayı</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {(event.sales || []).map((sale, i) => (
          <tr key={sale._id} className="pointer">
            <td>
              <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                <b><p className="m-0 text-center">{i + 1}</p></b>
              </div>
            </td>
            <td className="w-25">
              <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                <img
                  className="mr-4"
                  style={{ objectFit: 'cover' }}
                  alt="event logo"
                  src={sale?.venue?.logo}
                  width={64}
                  height={64}
                />
                {sale.venue.name}
              </div>
            </td>
            <td>
              <div className="d-flex flex-direction-row align-items-center" style={{ height: '64px' }}>
                {sale?.dates?.min
                  ? ((sale?.dates?.min !== sale?.dates?.max)
                    ? (
                      <>
                        <p className="m-0">{dayjs.utc(sale?.dates?.min).format('DD MMM YYYY')}</p>
                        <i className="fa fa-arrow-right mx-2" />
                        <p className="m-0">{dayjs.utc(sale?.dates?.max).format('DD MMM YYYY')}</p>
                      </>
                    )
                    : <p className="m-0">{dayjs.utc(sale?.dates?.min).format('DD MMM YYYY')}</p>)
                  : <p className="m-0">Seans bilgisi girilmemiş</p>}
              </div>
            </td>
            <td>
              <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                {sale.sessions ? `${sale.sessions} Seans` : 'Seans bilgisi girilmemiş'}
              </div>
            </td>
            <td className="text-center">
              <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                <ConfirmationChip
                  label={sale.vendor_confirmation ? strings[`sale-confirm-${sale?.vendor_confirmation}`] : 'Yeni Satış'}
                  tooltip="Etkinliğinizin satışa başlaması için ES Events tarafından onaylanması gerekiyor."
                  status={sale?.vendor_confirmation || 'pending'}
                />
              </div>
            </td>
            <td className="text-center">
              <div className="d-flex flex-direction-row align-items-center ml-2" style={{ height: '64px' }}>
                <ConfirmationChip
                  label={sale.vendor_confirmation ? strings[`sale-confirm-${sale?.venue_confirmation}`] : 'Yeni Satış'}
                  tooltip="Etkinliğinizin satışa başlaması için mekan tarafından onaylanması gerekiyor."
                  status={sale?.venue_confirmation || 'pending'}
                />
              </div>
            </td>
            <td>
              <div className="d-flex flex-direction-row align-items-center" style={{ height: '64px' }}>
                <Link to={`/events/${event._id}/sales/${sale._id}`}>
                  <button
                    disabled={!sale?.sessions}
                    type="button"
                    className="m-0 btn btn-primary"
                  >
                    {sale?.sessions ? 'Detayları Gör' : 'Seans Yok'}
                  </button>
                </Link>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </main>
);

export default EventSalesTable;
