/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { Link } from 'wouter';

const GENDERS = {
  male: 'Erkek',
  female: 'Kadın',
  other: 'Belirtmemiş',
};

const UsersTable = ({
  data, total, index, pager, onClickActive,
}) => (
  <main className="main table-responsive">
    <h4 className="flex-grow-1 mb-3">{data?.length > 0 ? `Toplam ${total} kullanıcı var` : 'Hiç kullanıcı yok'}</h4>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Kullanıcı Adı</th>
          {/* <th scope="col">Hesabı</th> */}
          <th scope="col" className="text-center">Cinsiyeti</th>
          <th scope="col" className="text-center">Fav. Etk.</th>
          <th scope="col" className="text-center">Fav. Mkn.</th>
          <th scope="col" className="text-center">Fav. Org.</th>
          <th scope="col">Doğum Tarihi</th>
          <th scope="col">Kayıt Tarihi</th>
          <th scope="col" className="text-center">Aktiflik</th>
        </tr>
      </thead>
      <tbody>
        {data.map((entity, i) => (
          <tr key={entity._id}>
            <td>{index + i + 1}</td>
            <td><Link to={`/users/${entity?._id}`}>{entity?.owner?.name}</Link></td>
            {/* <td>
              <Link to={`/accounts/${entity?._id}`}>
                <i className="fa fa-link btn-link pointer" />
              </Link>
            </td> */}
            <td className="text-center">{GENDERS[entity.gender]}</td>
            <td className="text-center">{entity.following_events}</td>
            <td className="text-center">{entity.following_venues}</td>
            <td className="text-center">{entity.following_organizers}</td>
            <td>{dayjs.utc(entity.birthday).format('DD MMM YYYY')}</td>
            <td>{dayjs.utc(entity.createdAt).format('DD MMM YYYY, HH:mm')}</td>
            <td>
              <button
                type="button"
                className={`w-100 btn btn-sm btn-${entity.owner.active ? 'success' : 'danger'}`}
                onClick={() => onClickActive(entity)}
              >
                {entity.owner.active ? 'Evet' : 'Hayır'}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="float-right">{pager}</div>
  </main>
);

export default UsersTable;
