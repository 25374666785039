/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { get } from '../../../libs/client';

import {
  CommonInput, CommonSelect, PaginationInput,
} from '../../../components/widgets';
import { QuerifySearch, DecodifySearch } from '../../../utils/StringUtils';
import stores from '../../../libs/stores';

import { EventsTable } from '../../../components/tables';

const PAGINATION_LIMIT = 15;
const PAGES = ['Aktif Etkinlikler', 'Onay Bekleyen Etkinlikler', 'Yeni Açılmış Etkinlikler', 'Geçmiş Etkinlikler'];

const Search = ({ disabled, query, onSearch }) => {
  const [defaults] = useState(DecodifySearch(query || window.location.search));
  const { register, reset, handleSubmit } = useForm({ defaultValues: defaults });
  useEffect(() => onSearch({ ...defaults }), []);
  return (
    <main className="main grid grid-slim align-items-end" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 0.5fr' }}>
      <CommonInput
        label="Etkinlik Adı"
        placeholder="Etkinlik adıyla arayın"
        register={register('name')}
        disabled={disabled}
      />
      <CommonInput
        label="Sanatçı"
        placeholder="Sanatçı adıyla arayın"
        register={register('artist')}
        disabled={disabled}
      />
      <CommonInput
        label="Mekan"
        placeholder="Mekan adıyla arayın"
        register={register('venue')}
        disabled={disabled}
      />
      <CommonInput
        label="Başlangıç"
        type="date"
        disabled={disabled}
        register={register('start')}
      />
      <CommonInput
        label="Bitiş"
        type="date"
        disabled={disabled}
        register={register('end')}
      />
      <CommonSelect
        label="Aktif mi?"
        placeholder="Bir statü seçin"
        disabled={disabled}
        defaultValue={defaults.active}
        options={[
          { value: 'true', name: 'Evet' },
          { value: 'false', name: 'Hayır' },
          { value: '', name: 'Hepsi' },
        ]}
        register={register('active')}
      />
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary btn-normal flex-grow-1"
          disabled={disabled}
          onClick={handleSubmit(onSearch)}
        >
          <i className="fa fa-search" />
        </button>
        {query && (
          <button
            type="button"
            className="btn btn-danger btn-normal flex-grow-1 ml-2"
            onClick={() => {
              onSearch({ ...defaults });
              reset(null);
            }}
          >
            <i className="fa fa-trash" />
          </button>
        )}
      </div>
    </main>
  );
};

const EventsGeneric = ({ initialQueries }) => {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState('&');

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const GetData = () => get(`/events/search?page=${total}&limit=${PAGINATION_LIMIT}&${initialQueries}&${query || ''}`)
    .then((res) => { setData(res.data); setPages(res.total); })
    .catch(console.log);

  useEffect(() => { GetData(); }, [query, total]);

  return (
    data
      ? (
        <main className="grid">
          {data?.length > 0
            ? (
              <>
                <Search onSearch={(q) => { setQuery(QuerifySearch(q)); setTotal(0); }} query={query} />
                <EventsTable
                  data={data}
                  total={pages}
                  index={(PAGINATION_LIMIT * total)}
                  pager={(
                    <p className="d-flex align-items-center mr-2">
                      sayfa
                      <CommonSelect
                        className="mx-2"
                        value={total + 1}
                        placeholder={1}
                        onChange={({ target }) => setTotal(target.value)}
                        options={Array.from(Array(Math.floor(Math.abs(pages - 1) / PAGINATION_LIMIT)))
                          .map((_, i) => ({ name: i + 2, value: i + 1 }))}
                      />
                      {`/ ${Math.floor(Math.abs(pages - 1) / PAGINATION_LIMIT) + 1}`}
                    </p>
                )}
                />
              </>
            )
            : (
              <CenteredInformation
                title=" Hiç etkinlik yok"
                subtitle="ES Events'ten organizatörlerin etkinlik eklemesi gerekiyor."
                fixed
              />
            )}
        </main>
      )
      : (<LoadingIndicator />)
  );
};

const Events = () => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    stores.setAppbar({
      left: (<h2 className="m-0"> Tüm Etkinlikler</h2>),
      // right: (
      //   <Link to="/events/new">
      //     <button
      //       type="button"
      //       className="btn btn-primary"
      //     >
      //       <i className="fa fa-plus mr-2" />
      //       YENİ ETKİNLİK
      //     </button>
      //   </Link>
      // ),
    });
    stores.setBreadCrumbs([]);
  }, []);

  return (
    <main className="grid">
      <PaginationInput
        className="mr-2"
        content={PAGES}
        onChange={setPage}
        page={page}
      />
      {page === 0 && (<EventsGeneric initialQueries="recent=true" Search={Search} />)}
      {page === 1 && (<EventsGeneric initialQueries="status=pending" Search={Search} />)}
      {page === 2 && (<EventsGeneric initialQueries="new=true" Search={Search} />)}
      {page === 3 && (<EventsGeneric initialQueries="past=true" Search={Search} />)}
    </main>
  );
};

export default Events;
