/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';
import { get } from '../../libs/client';
import LoadingIndicator from './LoadingIndicator';

const SEATING_TARGET = 'https://seating.hibilet.com';
// const SEATING_TARGET = 'http://localhost:5173';
// const SEATING_TARGET = 'http://10.241.158.51:1234';

const SeatingEngine = ({ plan }) => {
  const engine = useRef(null);
  const [loading, setLoading] = useState('Oturma planı sistemi yükleniyor...');

  const Message = (msg) => {
    if (msg.method) {
      engine?.current?.contentWindow?.postMessage(msg, '*');
    } else { console.log('No method given.'); }
  };

  const Controllers = {
    'HOOK/ OnPaint': () => null,
    'HOOK/ OnLoadLayout': () => setLoading(false)
    ,
  };

  const MessageBroker = ({ origin, data }) => {
    if (origin !== window.origin) {
      try {
        Controllers[data.type](data.data);
      } catch (e) {
        console.log(data);
      }
    }
  };

  const RemoveEventListeners = () => {
    window.removeEventListener('message', MessageBroker);
  };

  useEffect(() => {
    if (engine.current) { window.addEventListener('message', MessageBroker); }
    return () => {
      engine.current = null;
      RemoveEventListeners();
    };
  }, [engine]);

  const GetLayout = () => {
    setLoading('Oturma planı indiriliyor...');
    get(`/venues/seating/${plan._id}`)
      .then(({ data }) => {
        Message({ method: 'POST/ UserType', data: 'organizer' }, '*');
        Message({ method: 'POST/ Category', data: -1 }, '*');
        setLoading('Oturma planı çiziliyor...');
        Message({ method: 'POST/ Layout', data }, '*');
        Message({ method: 'POST/ PaintSeats' }, '*');
      })
      .catch(() => alert('Oturma planı indirilemedi'));
  };

  return (
    <div>
      <section className="grid grid-slim">
        <main className="d-flex flex-column align-items-center justify-content-center bg-light">
          <div className="d-flex flex-row w-100 engine">
            <iframe
              ref={engine}
              className="seating-plan w-100"
              title="Oturma Planı"
              src={SEATING_TARGET}
              onLoad={() => GetLayout()}
            />
            {loading && <LoadingIndicator message={loading} />}
          </div>
        </main>
      </section>
    </div>
  );
};

export default SeatingEngine;
