/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import strings from '../../localization';
import ConfirmationChip from './ConfirmationChip';
import { get, post } from '../../libs/client';
import { CommonInput } from '../widgets';

const OrganizerSaleDialog = ({ organizerSale, sale, onClose }) => {
  const { register, handleSubmit } = useForm();
  const [recommendedPrice, setRecommendedPrice] = useState(null);

  const GetRecommendedPrice = () => {
    get(`/sales/${sale._id}/recommend`)
      .then(({ data }) => { setRecommendedPrice(data); })
      .catch(() => alert('Fiyat önerisi bulunamadı'));
  };

  useEffect(() => { GetRecommendedPrice(); }, []);

  const UpsertSale = (form) => {
    post('/sales/events/', {
      ...form, sale: sale._id, event: sale.event._id, owner: sale.owner,
    }).then(({ message }) => {
      if (message === 'update-sale-event') {
        alert('Etkinlik ödemesi güncellendi');
      } else {
        alert('Etkinlik ödemesi oluşturuldu');
      }
      onClose();
    }).catch(() => {
      alert('Ödeme oluşturulamadı');
    });
  };

  const percentiles = [0.005, 0.01, 0.015, 0.02, 0.025, 0.03, 0.05];

  return (
    <div className="modal" style={{ display: 'block', background: '#000000AA' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Etkinlik Ödemesi</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <CommonInput
              label="Organizatör tarafından ödenecek tutarı girin"
              placeholder="1.00"
              className="mb-2"
              register={register('amount', { required: true, valueAsNumber: true })}
              defaultValue={organizerSale?.amount}
              append={<p>EUR</p>}
              type="number"
            />
            {recommendedPrice ? (
              <div>
                <div className="grid grid-2" style={{ width: '100%' }}>
                  <p>Potansiyel Ciro (₺)</p>
                  <p style={{ textAlign: 'right' }}>
                    {`${Math.ceil(recommendedPrice.sessions.reduce((a, b) => a + b.gross, 0)).toLocaleString()}`}
                    {' - '}
                    ₺
                  </p>
                </div>
                <div className="grid grid-2" style={{ width: '100%' }}>
                  <p>Potansiyel Ciro (€)</p>
                  <p style={{ textAlign: 'right' }}>
                    {`${Math.ceil(recommendedPrice.sessions.reduce((a, b) => a + b.inEuros, 0)).toLocaleString()}`}
                    {' - '}
                    €
                  </p>
                </div>
                <hr style={{ margin: '0.5rem 0' }} />
                <p>Önerilen Fiyatlar</p>
                <hr style={{ margin: '0.5rem 0' }} />
                {percentiles.map((percentile) => (
                  <div className="grid">
                    <div className="d-flex">
                      <p className="fl-1">{`% ${(100 * percentile).toFixed(1)}`}</p>
                      <p>
                        {`${Number((recommendedPrice.sessions.reduce((a, b) => a + b.inEuros, 0) * percentile).toFixed(2)).toLocaleString()}`}
                        {' - '}
                        €
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : <p>Önerilen Fiyatlar Yükleniyor</p>}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit(UpsertSale)}
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrganizerSale = ({ sale, compact, onClose }) => {
  const [organizerSale, setOrganizerSale] = useState(null);
  const [showDialog, setShowDialog] = useState(null);

  const GetSale = () => {
    get(`/sales/events/${sale.event._id}/sale/${sale._id}`)
      .then(({ data }) => { setOrganizerSale(data); })
      .catch(() => alert('Etkinlik ödemesi bulunamadı'));
  };

  useEffect(() => { GetSale(); }, []);
  useEffect(() => !showDialog && onClose(), [showDialog]);

  return (
    <>
      <div className="mb-0 d-flex flex-column justify-content-end">
        {!compact && <label title="OKAY">ES Events Ödemesi</label>}
        <ConfirmationChip
          label={organizerSale ? strings[`event-sale-${organizerSale?.status}`] : 'Ödeme Oluştur'}
          status={organizerSale?.status || 'pending'}
          onClick={() => setShowDialog(true)}
          disabled={organizerSale?.status === 'success'}
        />
      </div>
      {showDialog && (
        <OrganizerSaleDialog
          organizerSale={organizerSale}
          sale={sale}
          onClose={() => {
            setShowDialog(false);
            GetSale();
          }}
        />
      )}
    </>
  );
};

export default OrganizerSale;
