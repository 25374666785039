/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useLocation, Link } from 'wouter';
import { observer } from 'remx';
import { Fragment } from 'react';

import { setToken } from '../../libs/storage';
import strings from '../../localization';
import stores from '../../libs/stores';

import Logo from '../../assets/hibilet-organizer.svg';

const sections = [
  {
    name: 'sidebar-title-admin',
    links: [
      { icon: 'clipboard', name: 'sidebar-link-home', to: '/home' },
      { icon: 'calendar', name: 'sidebar-link-events', to: '/events?recent=true&active=true' },
      { icon: 'line-chart', name: 'sidebar-link-sales', to: '/reports?status=success' },
    ],
  },
  {
    name: 'sidebar-title-app',
    links: [
      { icon: 'star', name: 'sidebar-link-promoted', to: '/promoteds' },
      { icon: 'file', name: 'sidebar-link-contracts', to: '/admin-contracts' },
      { icon: 'credit-card', name: 'sidebar-link-payments', to: '/payments' },
      // { icon: 'money', name: 'sidebar-link-providers', to: '/admin-provider' },
      // { icon: 'phone', name: 'sidebar-link-app', to: '/app' },
    ],
  },
  {
    name: 'sidebar-title-account',
    links: [
      { icon: 'users', name: 'sidebar-link-accounts', to: '/accounts?active=true' },
      { icon: 'ticket', name: 'sidebar-link-organizers', to: '/organizers?active=true' },
      { icon: 'building', name: 'sidebar-link-venues', to: '/venues?active=true' },
      { icon: 'user', name: 'sidebar-link-users', to: '/users?active=true' },
      {
        icon: 'sign-out',
        name: 'sidebar-link-logout',
        to: () => {
          if (window.confirm('Çıkış yapmak istediğinize emin misiniz?')) {
            setToken('');
            window.location.reload();
          }
        },
      },
    ],
  },
];

const Sidebar = () => {
  const [location] = useLocation();
  return (
    <div className={`sidebar ${stores.getShowSidebar() ? 'sidebar-show' : ''}`}>
      <section>
        {(window.location.host.includes('dev-')) && (
          <p
            className="position-absolute"
            style={{
              right: '0.5rem',
              top: '2.5rem',
              color: 'black',
              background: 'white',
              padding: '0.25rem',
              borderRadius: '0.5rem',
              border: '1px solid black',
            }}
          >
            DEV
          </p>
        )}
        <Link to="/" onClick={() => stores.setTab('/')}>
          <img className="pointer" alt="ES Events" src={Logo} width="100%" />
        </Link>
        <hr className="my-2" />
      </section>
      <section className="menu">
        {sections.map((section) => (
          <Fragment key={section.name}>
            <h5>{strings[section.name]}</h5>
            {section.links.map(({ icon, name, to }) => (
              <Link
                key={name}
                className={`mb-2 btn ${(to.toString().includes(location.split('/')[1].toString())) ? 'btn-primary' : ''} w-100 text-left`}
                to={typeof (to) === 'string' ? to : '/'}
                onClick={() => (typeof (to) === 'string' ? stores.setTab(to) : to())}
              >
                <i className={`fa fa-${icon} mr-2`} />
                {strings[name]}
              </Link>
            ))}
          </Fragment>
        ))}
      </section>
      <section className="d-flex flex-column align-items-center">
        {/* <img
          alt="guide"
          className="w-50"
          style={{ filter: 'hue-rotate(335deg)' }}
          src="https://demos.creative-tim.com/argon-dashboard/assets/img/illustrations/icon-documentation.svg"
        />
        <p><b>{strings['sidebar-title-help']}</b></p>
        <p className="mb-2">{strings['sidebar-link-help']}</p> */}
        <a to="/" className="btn btn-secondary w-100">{strings['sidebar-link-guide']}</a>
      </section>
    </div>
  );
};

export default observer(Sidebar);
